import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';

//Calendar
import calendar from './calendar/reducer';

//chat
import chat from './chat/reducer';

//invoices
import invoices from './invoices/reducer';

//contacts
import contacts from './contacts/reducer';

import orderReducer from '../Redux/order/orderSlice';

import userReducer from '../Redux/user/userSlice';
import countryReducer from '../Redux/user/countrySlice';
import marketplaceReducer from '../Redux/marketplace/marketplaceSlice';
import settingReducer from '../Redux/settings/settingSlice';
import callbackReducer from '../Redux/callback/callbackSlice';
import bccwReducer from '../Redux/bccworder/bccwSlice';
import refundReducer from '../Redux/refund/refundSlice';
import permissionsReducer from '../Redux/permissions/permissionsSlice';
import photoShootReducer from '../Redux/photoshoot/photoshootSlice';
import dashboardReducer from '../Redux/dashboard/dashboardSlice';
import CustomerReducer from '../Redux/customers/customerSlice';
import reviewReducer from '../Redux/reviews/reviewSlice';
import productsReducer from '../Redux/products/productsSlice';
import importexportReducer from '../Redux/importexport/importexportSlice';
import deliveryReducer from '../Redux/delivery/deliverySlice';
import queueReducer from '../Redux/queue/queueSlice';
import createOrderSlice from '../Redux/order/createOrderSlice';
import invoiceReducer from '../Redux/invoice/invoiceSlice';
import walletReducer from '../Redux/wallet/walletSlice';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  invoices,
  contacts,
  order: orderReducer,
  user: userReducer,
  country: countryReducer,
  marketPlace: marketplaceReducer,
  setting: settingReducer,
  callback: callbackReducer,
  deliveryReport: deliveryReducer,
  queueMaster: queueReducer,
  bccw: bccwReducer,
  refund: refundReducer,
  permissions: permissionsReducer,
  photoshoot: photoShootReducer,
  dashboard: dashboardReducer,
  customer: CustomerReducer,
  review: reviewReducer,
  products: productsReducer,
  importexport: importexportReducer,
  createorder: createOrderSlice,
  ordInvoice: invoiceReducer,
  wallet: walletReducer,
});

export default rootReducer;
