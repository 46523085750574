import React from 'react';
import { MetaTags } from 'react-meta-tags';
//import Breadcrumbs
import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import ReturnOrderList from './components/ReturnOrderList';
import 'react-datepicker/dist/react-datepicker.css';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import { Col, Container, Row, FormGroup, InputGroup } from 'reactstrap';

const ReturnOrders = () => {
  const title = 'Orders';
  const description = 'Manage Return Orders';

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Return Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Orders"
                breadcrumbItem="Manage Return Orders"
              />
            </Col>
            <Col className="textAlignRight ">
              <a href={`/CreateFormReturn`} className="btn btn-dark">
                Create New Returns
              </a>
            </Col>
          </Row>

          <ReturnOrderList />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReturnOrders;
