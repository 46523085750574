import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

//Import Icons
import FeatherIcon from 'feather-icons-react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

//Import images
import giftBox from '../../assets/images/giftbox.png';

//i18n
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { v4 } from 'uuid';
import { fetchUserMenuLists } from '../../pages/Permissions/action/action';
import { sideBarMenuStatus } from '../../Redux/user/countrySlice';

const SidebarContent = (props) => {
  let menuIndex = 0;
  let subMenuIndex = 100;
  const [userMenu, setUserMenu] = useState('');
  const [subMenuActive, setsubMenuActive] = useState('');
  const [menuCollapse, setmenuCollapse] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const userObj = JSON.parse(localStorage.getItem('authUser'));
      if (userObj?.role_id !== undefined) {
        fetchUserMenuLists({
          params: { role_id: userObj?.role_id },
          dispatch,
        });
      }
    }
  }, []);

  const { UserMenuList = [], isLoading } = useSelector(
    (state) => state.permissions
  );
  var menuData = '';
  useEffect(() => {
    if (UserMenuList?.code === 100) {
      //

      menuData = JSON.parse(JSON.stringify(UserMenuList?.data));

      setUserMenu(menuData);
    } else {
      setUserMenu('');
    }
  }, [UserMenuList]);
  const MainMenu = [];

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_mode = params.get('order_mode');

  let UserRoleID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserRoleID = userObj?.role_id;
    }
  }
  const ref = useRef();

  const activateParentDropdown = React.useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const expandDrawer = (menuId) => {
    setmenuCollapse(menuId);
    document.body.setAttribute(`data-sidebar-size`, `lg`);
  };

  const sideMenuActiveUpdate = (menuID, subMenuId) => {
    let menuIds = menuID + '-' + subMenuId;
    dispatch(sideBarMenuStatus(menuIds));
    if (menuID !== '') {
      localStorage.setItem('SIDEMENU_IDS', menuIds);
    } else {
      localStorage.setItem('SIDEMENU_IDS', '');
    }
    setsubMenuActive(subMenuId);
  };

  const { country = [] } = useSelector((state) => state.country);
  const { sideMenuActive = [] } = useSelector((state) => state.country);

  const currentCountry = localStorage.getItem('WEBSITE_COUNTRY');
  const currentMenus = localStorage.getItem('SIDEMENU_IDS');
  // console.log("currentMenus------",currentMenus)
  let pathname = window.location.pathname;
  /*  let menuActiveId = "";
  let subMenuActiveId = ""; */
  const [menuActiveId, setMenuActiveId] = useState('');
  const [subMenuActiveId, setSubMenuActiveId] = useState('');
  useEffect(() => {
    pathname = window.location.pathname;
  }, [window.location.pathname]);
  useEffect(() => {
    if (sideMenuActive.length > 0) {
      const splitIds = sideMenuActive.split('-');
      //console.log("splitIds------",splitIds)
      setMenuActiveId(splitIds[0]);
      setSubMenuActiveId(splitIds[1]);
    } else if (currentMenus !== null) {
      const splitId = currentMenus.split('-');
      //console.log("splitIds------",splitId)
      setMenuActiveId(splitId[0]);
      setSubMenuActiveId(splitId[1]);
    } else {
      setMenuActiveId();
      setSubMenuActiveId();
    }
  }, [pathname, currentMenus]);
  const renderSideMenu = (menu) => {
    if (menu?.children.length > 0) {
      return (
        <li
          className={`${
            menu?.menu_id == menuCollapse || menu?.menu_id == menuActiveId
              ? 'mm-active'
              : ''
          }`}
          key={menu?.menu_id}
        >
          {' '}
          <Link
            to="#"
            className="has-arrow"
            onClick={() => {
              expandDrawer(menu?.menu_id);
            }}
          >
            <i className={menu?.icon}></i>
            <span>{menu?.label}</span>
          </Link>
          <ul
            className={`sub-menu ${
              menu?.menu_id == menuCollapse || menu?.menu_id == menuActiveId
                ? 'mm-show'
                : 'mm-collapse'
            } `}
            key={v4()}
          >
            {menu?.children?.map((submenu) => {
              // console.log("submenu------",submenu)
              return (
                <li
                  key={submenu?.menu_id}
                  className={`${
                    submenu?.menu_id == subMenuActive ||
                    submenu?.menu_id == subMenuActiveId
                      ? 'mm-active'
                      : ''
                  }`}
                >
                  <Link
                    className={` ${
                      submenu?.menu_id == subMenuActive ||
                      submenu?.menu_id == subMenuActiveId
                        ? 'active'
                        : ''
                    }`}
                    to={{
                      pathname: submenu?.slug,
                      state: { from: Date?.now() },
                    }}
                    onClick={() =>
                      sideMenuActiveUpdate(menu?.menu_id, submenu?.menu_id)
                    }
                  >
                    <span>{submenu?.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
      );
      /**/
    } else {
      /*  if (pathname != menu?.slag) {
        dispatch(sideBarMenuStatus(''));
      } */
      return (
        <li
          className={`${menu?.slug == pathname ? 'mm-active' : ''}`}
          key={menu?.menu_id}
          onClick={() => sideMenuActiveUpdate('', '')}
        >
          <Link to={menu?.slug} className="">
            <i className={menu?.icon}></i>
            <span className="">{menu?.label}</span>
          </Link>
        </li>
      );
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {userMenu !== '' ? (
              <>
                {userMenu?.map((menus) => {
                  return renderSideMenu(menus);
                })}
              </>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
