import { Col, Container, Row } from 'reactstrap';
import { DashBreadcrumb } from '../../components/Common/Breadcrumb';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getWalletList } from './action';
import WalletListItems from './components/WalletListItems';

const Wallet = () => {
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Wallet List</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb
                title="Wallet"
                breadcrumbItem="Manage Customer Wallet"
              />
            </Col>
            <Col
              xl={6}
              className="text-end"
              style={{ display: 'contents' }}
            ></Col>
          </Row>

          <WalletListItems />
        </Container>
      </div>
    </>
  );
};
export default Wallet;
