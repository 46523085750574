// import { iteratorSymbol } from "immer/dist/internal";
import React, { useState } from 'react';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slideshow from './returnImageSlide';

function ReturnOrderItemDetails({ itemData, orderData }) {
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [isFits, setisFits] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function tog_small() {
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  return (
    <>
      <Card className="OrderItemDetails">
        <CardHeader>
          <CardTitle>ORDER ITEM DETAILS</CardTitle>
        </CardHeader>

        {itemData !== undefined ? (
          <>
            <CardBody className="px-0">
              <div className="table-responsive dt-table">
                <Table className=" table mb-0">
                  <thead>
                    <tr>
                      <th>Details</th>
                      <th>Return Reason</th>
                      <th>Qty</th>
                      <th>Return Qty</th>
                      <th>Original Price</th>
                      <th>Now Price</th>
                      <th>Discount</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemData?.map((item, itemInx) => {
                      return (
                        <tr key={itemInx} classname="returnTr">
                          <td className="table-bordered returnTd">
                            <div>
                              {/* {isFits ? (
                        <Lightbox
                            mainSrc={item?.UploadImage}
                            enableZoom={false}
                            imageCaption={
                              item?.ItemName
                            }
                            onCloseRequest={() => {
                                setisFits(!isFits)
                            }}
                        />
                    ) : null}
                            <img
                            onClick={() => {
                              setisFits(true)
                          }}
                              alt=""
                              width="100"
                              height="100"
                              className="img-thumbnail1"
                              src={item?.UploadImage}
                            /> */}
                              <Slideshow imageArray={item?.UploadImage} />

                              <div
                                className="d-flex flex-column"
                                style={{ maxWidth: '75%', marginLeft: '10px' }}
                              >
                                <span>Name : {item?.ItemName}</span>
                                <span>
                                 CST NO :{' '}
                                  <strong className="status pending">
                                    {' '}
                                    {orderData?.CstNo}
                                  </strong>
                                </span> 
                                <span>Sku : {item?.ItemSku}</span>
                                <span>SO Number : {item?.LsReference}</span>
                                <span>
                                  Customer Item Condition :{' '}
                                  <strong className="status pending">
                                    {' '}
                                    {item?.ItemCondition}
                                  </strong>
                                </span>
                                <span>
                                  Approved Item Condition :{' '}
                                  <strong className="status pending">
                                    {' '}
                                    {item?.ApprovedItemCondition}
                                  </strong>
                                </span>
                                <span>
                                  Approved Qty :{' '}
                                  <strong className="status pending">
                                    {' '}
                                    {item?.ApprovedItemQty}
                                  </strong>
                                  </span>
                                  
                                <span>Resolution : {item?.Resolution}</span>

                                <span>
                                  CustomerComments :{' '}
                                  <strong className="status ">
                                    {item?.CustomerComments}
                                  </strong>
                                </span>
                                <span>Resolution : {item?.Resolution}</span>

                                <span>
                                  Time Slot :{' '}
                                  <strong className="status ">
                                    {item?.Time_slot}
                                  </strong>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="table-bordered align-middle text-center">
                            {item?.ReturnReason}
                          </td>
                          <td className="table-bordered align-middle text-center">
                            {item?.QtyOrdered}
                          </td>
                          <td className="table-bordered align-middle text-center">
                            {item?.ReturnItemQty}
                          </td>
                          <td className="table-bordered align-middle text-center">
                            {orderData?.Currency}{' '}
                            {Number(item?.OrginalPrice).toFixed(2)}
                          </td>
                          <td className="table-bordered align-middle text-center">
                            {orderData?.Currency} {item?.NowPrice}
                          </td>
                          <td className="table-bordered align-middle text-center">
                            {orderData?.Currency}{' '}
                            {item?.DiscountPrice === ''
                              ? '0.00'
                              : Number(
                                  (item?.DiscountPrice / item?.ReturnItemQty) *
                                    item?.ReturnItemQty
                                ).toFixed(2)}
                          </td>
                          <td className="table-bordered align-middle text-center">
                            {orderData?.Currency}{' '}
                            {Number(
                              (item?.TotalPrice / item?.ReturnItemQty) *
                                item?.ReturnItemQty
                            ).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <CardBody>
              <Skeleton count={6} />
            </CardBody>
          </>
        )}
      </Card>
    </>
  );
}
export default ReturnOrderItemDetails;
