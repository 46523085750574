import { useState, useEffect, button } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, Placeholder } from 'rsuite';
import { orderBy } from 'lodash';
import React from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from 'reactstrap';
import { nodeName } from 'rsuite/esm/DOMHelper';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function ReturnMarketDetailsBlock({ orderData }) {
  const paymentLabel = {
    payfort: 'Payfort',
    payfort_fort_cc: 'Payfort',
    payfort_fort_installments: 'Payfort Installment',
    apple_pay: 'Payfort - Apple Pay',
    payfort_omannet: 'Payfort - Oman Net',
    checkoutcom_card_payment: 'Checkout',
    tap: 'Tap',
    tap_apple_pay: 'Tap - Apple Pay',
    tap_careem_pay: 'Tap - Careem Pay',
    tap_knet: 'Tap KNET',
    cashew: 'Cashew',
    tamara: 'Tamara',
    spotii: 'Spotii',
    postpay: 'Postpay',
  };

  const [dmsStsLoad, setDmsStsLoad] = useState(false);
  const [generatePaymentLoad, setgeneratePaymentLoad] = useState(false);
  const [generatePayfortPaymentLoad, setgeneratePayfortPaymentLoad] =
    useState(false);
  const [generatePostPayPaymentLoad, setgeneratePostPayPaymentLoad] =
    useState(false);
  const [updatecoordinateLoad, setupdateCoordinateLoad] = useState(false);

  const dispatch = useDispatch();
  const [isMap, setIsMap] = useState(false);
  const onMapClose = () => {
    setIsMap(false);
  };
  const onMapOpen = () => {
    setIsMap(true);
  };

  const [isCoordinates, setCoordinates] = useState(false);
  const Latitude = orderData?.ShippingDetails?.[0]?.Latitude;
  const Longitude = orderData?.ShippingDetails?.[0]?.Longitude;
  const entity_id = orderData?.OrderId;
  if (Latitude === 0 && Longitude === 0) {
    setCoordinates(true);
  }

  const { updateLatLng = [], isLoading } = useSelector((state) => state.order);
  const { dms = [], dmsLoading } = useSelector((state) => state.order);
  const { paymentTap = [], paymentLoading } = useSelector(
    (state) => state.order
  );
  const { paymentPayfort = [], paymentPayfortLoading } = useSelector(
    (state) => state.order
  );
  const { paymentPostpay = [], paymentPostpayLoading } = useSelector(
    (state) => state.order
  );

  const { coordinates = [], coordinateLoading } = useSelector(
    (state) => state.order
  );

  const paymentInvoiceDetails = orderData?.PaymentInvoiceDetails;

  const renderPaymentDetils = (payData) => {
    const paymentMode = payData?.PaymentMode;

    const paymentGateway = payData?.Gateway;
    const paymentStatus = payData?.PaymentStatus;

    if (paymentMode === 'cashondelivery') {
      return (
        <div className="paymentDetailSec">
          <label>{paymentGateway}</label>
          <label>
            COD Fee: {orderData?.Currency} {orderData?.COD_charges}
          </label>
        </div>
      );
    } else if (
      paymentMode === 'payfort_fort_cc' &&
      (orderData?.ShippingDetails?.[0]?.Country === 'AE' ||
        orderData?.ShippingDetails?.[0]?.Country === 'QA')
    ) {
      return (
        <div classname="paymentDetailSec">
          <label>
            <b>{paymentGateway}</b>
          </label>
          <label>
            Status:{' '}
            <b className={paymentStatus === true ? 'successBtn' : 'failedBtn'}>
              {paymentStatus === true ? 'Success' : 'Failed'}
            </b>
          </label>
          <label>
            <b>Payment provider :</b>Payfort{' '}
          </label>

          <label>
            <b> Transation ID:</b> {orderData?.TransactionId}
          </label>
        </div>
      );
    } else {
      return (
        <div className="paymentDetailSec">
          <label>
            Status:{' '}
            <b className={paymentStatus === true ? 'successBtn' : 'failedBtn'}>
              {paymentStatus === true ? 'Success' : 'Failed'}
            </b>
          </label>

          <label>
            Payment provider:<b> {paymentLabel[paymentMode]}</b>
          </label>
          <label>
            Transation ID: <b>{orderData?.TransactionId}</b>
          </label>
        </div>
      );
    }
  };
  const renderShippingDetails = (shippingData) => {
    const ShippingMethod = shippingData?.ShippingMethod;
    const ShippingCharge = shippingData?.ShippingCharge;

    if (ShippingMethod === 'bestway') {
      return (
        <div className="paymentDetailSec">
          <label>
            Shipping Fee: {orderData?.Currency}{' '}
            {ShippingCharge === '' ? '0.00' : ShippingCharge}
          </label>
          <br />
        </div>
      );
    } else {
      return (
        <div className="paymentDetailSec">
          <label>{ShippingMethod}</label>
        </div>
      );
    }
  };
  return (
    <>
      <Row className="row-fluid-scroll">
        <Col md={6} xl={8}>
          <Card>
            <CardHeader className="bg-light">
              <CardTitle>CUSTOMER DETAILS</CardTitle>
            </CardHeader>
            {orderData !== undefined ? (
              <div className="paymentDetailSec">
                <CardBody>
                  <label>
                    <strong>{orderData?.CustomerName}</strong>
                  </label>

                  <label>{orderData?.ShippingDetails?.[0]?.Address1}</label>

                  <label>
                    <b>Email : </b>
                    {orderData?.Email}
                  </label>
                </CardBody>
              </div>
            ) : (
              <>
                <CardBody>
                  <Skeleton count={8} />
                </CardBody>
              </>
            )}
          </Card>
        </Col>

        <Col md={6} xl={4}>
          <Row>
            <Card className="card-h-100 OrderDetailsBox">
              <CardHeader className="bg-light">
                <CardTitle>SHIPPING DETAILS</CardTitle>
              </CardHeader>
              <CardBody>
                {orderData !== undefined ? (
                  <>{renderShippingDetails(orderData)}</>
                ) : (
                  <>
                    <Skeleton count={5} />
                  </>
                )}
              </CardBody>
            </Card>
          </Row>
        </Col>
      </Row>
    </>
  );
}
export default ReturnMarketDetailsBlock;
