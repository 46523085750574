import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Spinner, Table } from 'reactstrap';
import WalletDetails from '../WalletDetails';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletList } from '../../action';
import DHPagination from '../../../../components/Pagination';
import { useUrlSearchParams } from 'use-url-search-params';
import FilterSearchQueue from '../../../../components/FilterSearch/FilterSearchQueue';
import moment from 'moment';

const WalletListItems = (props) => {
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState({ page: 0 });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageLimit, setPageLimit] = useState({ limit: 10 });
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [searchFilter, setSearchFilter] = useUrlSearchParams({ search: '' });
  const [statusFilter, setStatusFilter] = useUrlSearchParams({ status: 'All' });

  const { walletList = [], isLoading } = useSelector((state) => state.wallet);
  const [isWalletDetailsOpen, setIsWalletDetailsOpen] = useState(false);
  const [walletMobileNo, setWalletMobileNo] = useState('');

  // Fetch data from the API
  const fetchList = useCallback(async (data) => {
    setIsDataLoading(true);
    try {
      const response = await getWalletList({ ...data }, dispatch);
      const { totalPages = 1, total = 0 } = response;
      // setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setTotalItems(total);
    } catch (error) {
      console.error('Failed to fetch Wallet list:', error);
    } finally {
      setIsDataLoading(false);
    }
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      const sts =
        statusFilter?.status === 'All'
          ? '0'
          : statusFilter?.status === 'Active'
          ? '1'
          : statusFilter?.status === 'InActive'
          ? '2'
          : '3';
      fetchList({
        search: searchFilter?.search,
        status: sts,
        country: 'ae',
        page: currentPage?.page,
        pageSize: pageLimit?.limit,
        advancedFilter,
      });
    }, 300); // Debounce time of 300 milliseconds
    return () => clearTimeout(delay);
  }, [
    searchFilter,
    statusFilter,
    advancedFilter,
    currentPage,
    pageLimit,
    fetchList,
  ]);

  const [copiedField, setCopiedField] = useState(null);

  const handleCopy = (text, fieldKey) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(fieldKey);
      setTimeout(() => setCopiedField(null), 2000); // Reset after 2 seconds
    });
  };
  const handleViewButton = (item) => {
    setWalletMobileNo(item);
    setIsWalletDetailsOpen(true);
  };
  return (
    <>
      <FilterSearchQueue
        type="wallet"
        setAdvancedFilter={setAdvancedFilter}
        setSearchFilter={setSearchFilter}
        setStatusFilter={setStatusFilter}
      />
      <div className="table-responsive fs-14">
        <table className="table table-hover table-centered align-middle table-nowrap mb-0">
          <thead className="fs-14">
            <tr>
              <th>SI.No</th>
              <th>Date</th>
              <th>Country</th>
              <th>Wallet ID</th>
              <th>Customer Details</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading || isDataLoading ? (
              <>
                <Spinner className="ms-2 sploader" color="primary" />

                {[...Array(2)].map((_, index) => (
                  <tr>
                    <td>
                      <p className="placeholder-glow d-flex flex-column gap-2">
                        <span className="placeholder w-25"></span>
                      </p>
                    </td>
                    <td>
                      <p className="placeholder-glow d-flex flex-column gap-2">
                        <span className="placeholder w-100"></span>
                        <span className="placeholder w-75"></span>
                      </p>
                    </td>
                    <td>
                      <p className="placeholder-glow d-flex flex-column gap-1">
                        <span className="placeholder w-25"></span>
                      </p>
                    </td>
                    <td>
                      <p className="placeholder-glow d-flex flex-column gap-2">
                        <span className="placeholder w-25"></span>
                      </p>
                    </td>
                    <td>
                      <p className="placeholder-glow d-flex flex-column gap-2">
                        <span className="placeholder w-75"></span>
                        <span className="placeholder w-50"></span>
                        <span className="placeholder w-50"></span>
                      </p>
                    </td>
                    <td>
                      <p className="placeholder-glow d-flex flex-column gap-2">
                        <span className="placeholder w-25"></span>
                      </p>
                    </td>
                    <td>
                      <p className="placeholder-glow  d-flex flex-column gap-2">
                        <span className="placeholder w-100"></span>
                      </p>
                    </td>
                  </tr>
                ))}
              </>
            ) : walletList && walletList.length > 0 ? (
              walletList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <p className="fs-13 my-1">
                        {(currentPage?.page || 1 - 1) * pageLimit?.limit +
                          (index + 1)}
                      </p>
                    </div>
                  </td>
                  <td>
                    <p className="fs-13 m-0 fw-normal">
                      {' '}
                      {moment(item?.createdAt).format('Do MMMM YYYY')}
                    </p>
                    <span className="fs-11 text-muted">
                      {moment(item?.createdAt).format('hh:mmA')}
                    </span>
                  </td>
                  <td>
                    <p className="fs-13 m-0 fw-normal">{item?.country}</p>
                  </td>
                  <td>
                    <p className="fs-13 my-1 fw-normal">
                      {item?.uniqueId ? (
                        <p className="d-flex align-items-center gap-1 m-0">
                          <i className="bx bx-wallet-alt"></i>
                          {item?.uniqueId}
                          <i
                            className={`bx ${
                              copiedField === `walletId-${index}`
                                ? 'bx-copy-alt'
                                : 'bx-copy'
                            }`}
                            onClick={() =>
                              handleCopy(item?.uniqueId, `walletId-${index}`)
                            }
                            style={{ cursor: 'pointer' }}
                            title={
                              copiedField === `walletId-${index}`
                                ? 'Copied!'
                                : 'Copy'
                            }
                          ></i>
                        </p>
                      ) : null}
                    </p>
                  </td>

                  <td>
                    {item?.firstName ? (
                      <p className="d-flex align-items-center gap-1 m-0">
                        {item?.firstName} {item.lastName}
                      </p>
                    ) : null}

                    {item?.email ? (
                      <p className="d-flex align-items-center gap-1 m-0">
                        {item?.email}
                      </p>
                    ) : null}

                    {item?.mobileNumber ? (
                      <p className="d-flex align-items-center gap-1 m-0">
                        <i className="bx bx-mobile"></i> {item?.mobileNumber}{' '}
                        <i
                          className={`bx ${
                            copiedField === `mobileNo-${index}`
                              ? 'bx-copy-alt'
                              : 'bx-copy'
                          }`}
                          onClick={() =>
                            handleCopy(item?.mobileNumber, `mobileNo-${index}`)
                          }
                          style={{ cursor: 'pointer' }}
                          title={
                            copiedField === `mobileNo-${index}`
                              ? 'Copied!'
                              : 'Copy'
                          }
                        ></i>
                      </p>
                    ) : null}
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-1">
                      <Badge
                        className={
                          item?.status === 1 ? 'bg-success' : 'bg-danger'
                        }
                      >
                        {item?.status === 1 ? 'Approved' : 'Hold'}
                      </Badge>
                    </div>
                  </td>

                  <td>
                    <Button
                      onClick={() => handleViewButton(item?.mobileNumber)}
                      color="light"
                      className="btn-light btn-rounded btn-sm px-3 py-2"
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No Items found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {walletList?.length > 0 ? (
        <DHPagination
          totalPages={totalPages}
          currentPage={currentPage?.page}
          setPaginationValue={setCurrentPage}
          setLimitValue={setPageLimit}
        />
      ) : null}

      <WalletDetails
        isWalletDetailsOpen={isWalletDetailsOpen}
        setIsWalletDetailsOpen={setIsWalletDetailsOpen}
        mobileNumber={walletMobileNo}
      />
    </>
  );
};

export default WalletListItems;
