import React from 'react';
import { MetaTags } from 'react-meta-tags';

import {
  Breadcrumbs,
  DashBreadcrumb,
} from '../../components/Common/Breadcrumb';
import OrderList from './components/OrderList';
import OrderExportReport from './components/OrderExportReport';
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Modal,
  Button,
} from 'reactstrap';
import 'react-drawer/lib/react-drawer.css';
import StoreSelection from './components/StoreSelection/StoreSelection.component';
import { includes } from 'lodash';
import { EXPORT_ALLOWED_USER_ROLES } from '../../constants/index';
import OrderDetailedViewPopup from './components/OrderDetailedViewPopup/OrderDetailedViewPopup';
const Orders = () => {
  const users = JSON.parse(localStorage?.getItem('authUser'));
  const title = 'Orders';
  const description = 'Manage Orders';

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mange Orders 1</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="Orders" breadcrumbItem="Manage Orders" />
            </Col>
            <Col xl={6} className="text-end" style={{ display: 'contents' }}>
              <StoreSelection
                onlyExport={
                  EXPORT_ALLOWED_USER_ROLES.includes(users?.role_id) ? '0' : '1'
                }
              />
              {EXPORT_ALLOWED_USER_ROLES.includes(users?.role_id) ? (
                <OrderExportReport />
              ) : (
                ''
              )}
            </Col>
          </Row>

          <OrderList />
          {/* <OrderDetailedViewPopup /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Orders;
