import React, { useCallback, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import ReactDrawer from 'react-drawer';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Button, Row, Col, Card, CardBody, Badge, Spinner } from 'reactstrap';
import FilterSearchQueue from '../../../../components/FilterSearch/FilterSearchQueue';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletBalanceInfo, getWalletTransactions } from '../../action';
import moment from 'moment';
import DHPagination from '../../../../components/Pagination';

const WalletDetails = (props) => {
  const dispatch = useDispatch();
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [isTransDataLoading, setIsTransDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState({ page: 0 });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageLimit, setPageLimit] = useState({ limit: 8 });
  const [advancedFilter, setAdvancedFilter] = useState({});
  const [searchFilter, setSearchFilter] = useState({ search: '' });
  const [statusFilter, setStatusFilter] = useState({ status: 'All' });
  const { isWalletDetailsOpen, setIsWalletDetailsOpen, mobileNumber } = props;
  const { walletBalanceInfo = {}, isLoading } = useSelector(
    (state) => state.wallet
  );

  const { walletTransactionList = [], isDataLoading } = useSelector(
    (state) => state.wallet
  );
  // Fetch data from the API
  const fetchBalanceInfo = async (data) => {
    setPosition('right');
    setOpen(!open);
    setIsBalanceLoading(true);
    try {
      await getWalletBalanceInfo({ ...data }, dispatch);
    } catch (error) {
      console.error('Failed to fetch Wallet list:', error);
    } finally {
      setIsBalanceLoading(false);
    }
  };

  // Fetch data from the API
  const fetchWalletTransactionList = useCallback(
    async (data) => {
      setIsTransDataLoading(true);
      try {
        const response = await getWalletTransactions({ ...data }, dispatch);
        console.log(response?.pageInfo, 'response?.pageInfo;');
        const { totalPages = 1, total = 0 } = response?.pageInfo;
        setTotalPages(totalPages);
        setTotalItems(total);
      } catch (error) {
        console.error('Failed to fetch Wallet list:', error);
      } finally {
        setIsTransDataLoading(false);
      }
    },
    [dispatch]
  );

  // Effect to fetch transactions when currentPage changes
  useEffect(() => {
    const delay = setTimeout(() => {
      if (isWalletDetailsOpen) {
        fetchWalletTransactionList({
          country: 'ae',
          mobileNumber,
          page: currentPage?.page,
          pageSize: pageLimit?.limit,
          search: searchFilter?.search,
          status: statusFilter?.status === 'All' ? '' : statusFilter?.status,
          advancedFilter,
          sortBy: 'createdAt',
          sortOrder: 'DESC',
        });
      }
    }, 300); // Debounce time of 300 milliseconds
  }, [
    currentPage,
    pageLimit,
    searchFilter,
    statusFilter,
    advancedFilter,
    isWalletDetailsOpen,
  ]);
  useEffect(() => {
    if (isWalletDetailsOpen) {
      fetchBalanceInfo({
        country: 'ae',
        mobileNumber,
      });
    }
  }, [isWalletDetailsOpen]);

  const [copiedField, setCopiedField] = useState(null);

  const handleCopy = (text, fieldKey) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(fieldKey);
      setTimeout(() => setCopiedField(null), 2000); // Reset after 2 seconds
    });
  };
  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);
  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
    setIsWalletDetailsOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
    setIsWalletDetailsOpen(false);
  };

  return (
    <>
      {/* <Button
        color="light"
        className="btn-light btn-rounded btn-sm px-3 py-2"
        onClick={getWalletBalance}
        disabled={isWalletDetailsOpen}
      >
        View Wallet Details
      </Button> */}
      <div className="walletDrawerContain">
        <ReactDrawer
          open={isWalletDetailsOpen}
          position={position}
          onClose={() => {
            setOpen(false);
            setIsWalletDetailsOpen(false);
          }}
        >
          <span
            onClick={toggleRightDrawer}
            className="position-absolute top-0 end-0 border-0 p-2 mx-2"
            aria-label="Close"
            style={{ zIndex: 9, cursor: 'pointer' }}
          >
            <i className="mdi mdi-close fs-22"></i>
          </span>
          <div
            className="vh-100"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            <Row>
              <Col md={4} lg={3} className="border-end vh-100">
                <div className="d-flex flex-column p-3 pe-1">
                  <Card className="card-animate shadow-none border-0">
                    {!isBalanceLoading && walletBalanceInfo?.walletId ? (
                      <CardBody className="p-0">
                        <div className="bg-info bg-gradient p-4 rounded-3">
                          <Row>
                            <Col
                              md={12}
                              className="d-flex justify-content-between align-items-center mb-2"
                            >
                              <i className="bx bx-wallet-alt h1 mb-0 text-white-50"></i>
                              <span className="fs-20 text-white-50 fw-semibold">
                                Wallet
                              </span>
                            </Col>
                            <Col md={12} className="mt-2">
                              <p className="fs-20 mb-0 text-white">
                                {walletBalanceInfo?.walletId
                                  .toString()
                                  .replace(/(\d{4})(?=\d)/g, '$1-')}
                              </p>
                            </Col>
                            <Col md={12} className="mt-5">
                              <p className="text-white-50 mb-0 text-white">
                                Account Holder
                              </p>
                              <p className="fw-medium fs-14 mb-0 text-white">
                                {walletBalanceInfo?.name}
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex flex-column mt-4">
                          <p className="fs-12 text-black-50 mb-0 text-center">
                            Wallet Balance
                          </p>
                          <h2 className="m-0 fs-30 text-center fw-semibold">
                            {walletBalanceInfo?.currency}{' '}
                            <CountUp
                              start={0}
                              end={walletBalanceInfo?.balance}
                              separator=","
                              duration={1}
                            />
                          </h2>
                        </div>
                        <div className="mt-4 p-2 bg-light rounded-3">
                          <p className="m-0 fs-11 text-muted text-center">
                            Wallet{' '}
                            <span className="badge bg-soft-success text-success fs-12 fw-medium">
                              Active
                            </span>{' '}
                            {moment(walletBalanceInfo?.createdAt).format(
                              'Do MMMM YYYY, hh:mmA'
                            )}
                          </p>
                        </div>
                      </CardBody>
                    ) : null}
                  </Card>

                  <Card className="card-animate shadow-none border">
                    <CardBody>
                      <h4 className="fs-14 mb-3">Additional Information</h4>
                      <p className="fs-12 text-muted mb-0 border-bottom py-2 pt-0">
                        Email ID :{' '}
                        <span className="fw-medium">
                          {walletBalanceInfo?.emailId}
                        </span>
                      </p>
                      <p className="fs-12 text-muted my-0  py-2">
                        Mob No :{' '}
                        <span className="fw-medium">
                          {walletBalanceInfo?.mobileNumber}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col md={8} lg={9}>
                <div className="p-3 ps-1">
                  <div className="d-flex justify-content-start gap-4 align-items-center py-4 border-bottom border-dashed">
                    <h4 className="mb-0">Wallet Transactions</h4>
                  </div>
                  <div className="pt-4">
                    {/* <FilterSearchQueue
                      type="wallet-transactions"
                      setAdvancedFilter={setAdvancedFilter}
                      setSearchFilter={setSearchFilter}
                      setStatusFilter={setStatusFilter}
                    /> */}
                    <div className="table-responsive fs-14">
                      <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                        <thead className="fs-14">
                          <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Country</th>
                            <th>Transaction ID</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Mode</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading || isDataLoading ? (
                            <>
                              <Spinner
                                className="ms-2 sploader"
                                color="primary"
                              />

                              {[...Array(2)].map((_, index) => (
                                <tr>
                                  <td>
                                    <p className="placeholder-glow d-flex flex-column gap-2">
                                      <span className="placeholder w-25"></span>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="placeholder-glow d-flex flex-column gap-2">
                                      <span className="placeholder w-100"></span>
                                      <span className="placeholder w-75"></span>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="placeholder-glow d-flex flex-column gap-1">
                                      <span className="placeholder w-25"></span>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="placeholder-glow d-flex flex-column gap-2">
                                      <span className="placeholder w-25"></span>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="placeholder-glow d-flex flex-column gap-2">
                                      <span className="placeholder w-75"></span>
                                      <span className="placeholder w-50"></span>
                                      <span className="placeholder w-50"></span>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="placeholder-glow d-flex flex-column gap-2">
                                      <span className="placeholder w-25"></span>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="placeholder-glow  d-flex flex-column gap-2">
                                      <span className="placeholder w-100"></span>
                                    </p>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : walletTransactionList &&
                            walletTransactionList.length > 0 ? (
                            walletTransactionList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <p className="fs-13 my-1">
                                      {' '}
                                      {(currentPage?.page || 1 - 1) *
                                        pageLimit?.limit +
                                        (index + 1)}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <p className="fs-13 m-0 fw-normal">
                                    {moment(item?.createdAt).format(
                                      'Do MMMM YYYY'
                                    )}
                                  </p>
                                  <span className="fs-11 text-muted">
                                    {moment(item?.createdAt).format('hh:mmA')}
                                  </span>
                                </td>
                                <td>
                                  <p className="fs-13 m-0 fw-normal">ae</p>
                                </td>
                                <td>
                                  <p className="fs-13 my-1 fw-normal">
                                    #{item?.token}
                                  </p>
                                </td>
                                <td>
                                  <div className="d-flex gap-2">
                                    <i
                                      className={`fs-22 bx bx-trending-${item?.mode === 'credit'
                                        ? 'up text-success'
                                        : 'down text-danger'
                                        }`}
                                    ></i>
                                    <div>
                                      {item?.title ? (
                                        <p className="d-flex align-items-center gap-1 fs-13 fw-normal m-0">
                                          {item?.title}
                                        </p>
                                      ) : null}
                                      {item?.store ? (
                                        <p className="fs-10 m-0 text-muted">
                                          Source: <b>{item?.store}</b>, Ref#:{' '}
                                          <b>{item?.transactionNo}</b>{' '}
                                          <i
                                            className={`bx ${copiedField === `redId-${index}`
                                              ? 'bx-copy-alt'
                                              : 'bx-copy'
                                              }`}
                                            onClick={() =>
                                              handleCopy(
                                                item?.transactionNo,
                                                `redId-${index}`
                                              )
                                            }
                                            style={{ cursor: 'pointer' }}
                                            title={
                                              copiedField === `redId-${index}`
                                                ? 'Copied!'
                                                : 'Copy'
                                            }
                                          ></i>
                                        </p>
                                      ) : null}
                                      {item?.expiryAt ? (
                                        <p className="fs-10 m-0 text-warning">
                                          {item?.expiryAt}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-1">
                                    <Badge
                                      className={`bg-${item?.status === 'approved'
                                        ? 'success'
                                        : 'danger'
                                        } text-capitalize`}
                                    >
                                      {item?.status}
                                    </Badge>
                                  </div>
                                </td>
                                <td>
                                  {item?.amount ? (
                                    <p
                                      className={`text-${item?.mode === 'credit'
                                        ? 'success'
                                        : 'danger'
                                        } fs-13 my-1 fw-bold text-right`}
                                    >
                                      {Number(item?.amount).toFixed(2)}
                                    </p>
                                  ) : null}
                                </td>
                                <td>
                                  {item?.mode ? (
                                    <p className="fs-13 my-1 fw-bold text-right text-capitalize">
                                      {item?.mode}
                                    </p>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>No Items found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {walletTransactionList?.length > 0 ? (
                    <DHPagination
                      totalPages={totalPages}
                      currentPage={currentPage?.page}
                      setPaginationValue={setCurrentPage}
                      setLimitValue={setPageLimit}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </ReactDrawer>
      </div>
    </>
  );
};

export default WalletDetails;
