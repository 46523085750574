import './AddToCart.scss';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useDispatch } from 'react-redux';
import { SuccessToast, ErrorToast } from '../../../../../utils/Toaster';
import { Toaster } from 'react-hot-toast';
import { addOrUpdateCart, removeItemFromCart } from '../../actions/action';
import { setPageLoader } from '../../../../../Redux/order/createOrderSlice';
import { Card } from 'react-bootstrap';
import { SelectPicker } from 'rsuite';
function CreateNewOrderComponent(props) {
  const {
    cart = {},
    showcart,
    setShowmapandAddress,
    setShowShippingControll,
  } = props || {};
  const { countryInfo } = cart || {};
  const cartItems = cart.items || [];
  const [customPrices, setCustomPrices] = useState({});
  const [disableNextToAddress, setDisableNextToAddress] = useState(false);
  const [cartItemClone, setCartItemClone] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  useEffect(() => {
    setCartItemClone(cart?.items || []);
    setShowShippingControll(true);
  }, [cart]);

  const dispatch = useDispatch();
  const addToCartQty = async (event, values, cp = null) => {
    event.preventDefault();
    dispatch(setPageLoader(true));
    const { sku, qty } = values;
    const cartUpdated = await addOrUpdateCart(
      { sku, qty: Number(qty || 1), cp },
      dispatch
    );
    if (cartUpdated?._id) {
      SuccessToast('New Item Add/Update to cart');
      dispatch(setPageLoader(false));
    } else {
      ErrorToast('Requested Qty not available');
      dispatch(setPageLoader(false));
    }
  };

  const updateCustomPrice = async (index) => {
    if (cartItemClone[index].sku === '990700100004' && !selectedService) {
      ErrorToast('Please choose a service name');
    } else {
      const cSkuvalue = cartItemClone[index].sku;
      const cPricevalue =
        cartItemClone[index].product.selectedPrice.specialPrice;
      const cQtyvalue = cartItemClone[index].qty;
      if (Number(cPricevalue) > 0) {
        dispatch(setPageLoader(true));
        setDisableNextToAddress(false);
        const cartUpdated = await addOrUpdateCart(
          {
            sku: cSkuvalue,
            qty: Number(cQtyvalue),
            cp: Number(cPricevalue),
            extraInfo: JSON.stringify({ serviceType: selectedService }),
          },
          dispatch
        );
        if (cartUpdated) {
          dispatch(setPageLoader(false));
          SuccessToast('Custom price updated');
        } else {
          dispatch(setPageLoader(false));
          ErrorToast('Requested Qty not avaialable');
        }
      } else {
        ErrorToast('Custom price cannot be empty');
      }
    }
  };

  const removeItem = (itemId) => {
    dispatch(setPageLoader(true));
    const removeCartItem = async (itemId) => {
      const removeItemRes = await removeItemFromCart({ itemId }, dispatch);
      if (removeItemRes) {
        SuccessToast('Item Removed from Cart');
        dispatch(setPageLoader(false));
      } else {
        ErrorToast('Item Remove error');
        dispatch(setPageLoader(false));
      }
    };
    removeCartItem(itemId);
  };

  const customPriceCheckboxChange = async (e, itemId, index, cp = null) => {
    setDisableNextToAddress(false);
    const isChecked = e.target.checked;
    if (isChecked) {
      setDisableNextToAddress(true);
      dispatch(setPageLoader(false));
    } else {
      const updatedCartItems = [...cartItemClone]; // Create a copy of the array
      updatedCartItems[index] = {
        ...updatedCartItems[index], // Create a copy of the item
        product: {
          ...updatedCartItems[index].product,
          selectedPrice: {
            ...updatedCartItems[index].product.selectedPrice, // Create a copy of the selectedPrice
            specialPrice: cartItems[index].product.selectedPrice.specialPrice, // Update the specialPrice
          },
          cart: {
            ...updatedCartItems[index].product.cart,
            subTotal:
              Number(updatedCartItems[index].qty) *
              Number(cartItems[index].product.selectedPrice.specialPrice),
          },
        },
      };
      setCartItemClone(updatedCartItems); // Update the state
    }
    setCustomPrices((prevState) => ({
      ...prevState,
      [itemId]: isChecked ? '' : undefined,
    }));
  };

  const ChangeCustomQty = (e, index) => {
    let newQty = parseInt(e.target.value);
    if (isNaN(newQty)) {
      newQty = 0;
    }
    //if (!isNaN(newQty) && newQty >= 0) {
    const updatedCartItems = [...cartItemClone]; // Create a copy of the array
    updatedCartItems[index] = {
      ...updatedCartItems[index], // Create a copy of the item
      qty: newQty, // Update the quantity
      product: {
        ...updatedCartItems[index].product,
        cart: {
          ...updatedCartItems[index].product.cart,
          subTotal:
            newQty * updatedCartItems[index].product.selectedPrice.specialPrice,
        },
      },
    };
    setCartItemClone(updatedCartItems); // Update the state
    //}
  };

  const ChangeCustomPrice = (e, index) => {
    let newPrice = e.target.value;
    if (isNaN(newPrice)) {
      newPrice = 0;
    }
    console.log(newPrice, 'newPrice');
    const updatedCartItems = [...cartItemClone]; // Create a copy of the array
    updatedCartItems[index] = {
      ...updatedCartItems[index], // Create a copy of the item
      product: {
        ...updatedCartItems[index].product, // Create a copy of the product
        selectedPrice: {
          ...updatedCartItems[index].product.selectedPrice, // Create a copy of the selectedPrice
          specialPrice: newPrice, // Update the specialPrice
        },
        cart: {
          ...updatedCartItems[index].product.cart,
          subTotal: newPrice * updatedCartItems[index].qty,
        },
      },
    };
    setCartItemClone(updatedCartItems); // Update the state
  };

  const serviceOptions = [
    { label: 'Installation Service Fee', value: 'Installation Service Fee' },
    { label: 'Storage Fee', value: 'Storage Fee' },
    { label: 'Inspection Fee', value: 'Inspection Fee' },
    {
      label: 'Out of Warranty Service Fee',
      value: 'Out of Warranty Service Fee',
    },
    { label: 'Delivery Fee', value: 'Delivery Fee' },
  ];

  const handleSelectServiceType = (value) => {
    setSelectedService(value);
  };

  return showcart ? (
    <>
      <Card>
        <CardHeader className="bg-light">
          <h6>Add a Product</h6>
        </CardHeader>
        <CardBody>
          <AvForm onValidSubmit={addToCartQty}>
            <Row>
              <Col className="col-md-auto">
                <AvField
                  name="sku"
                  label="SKU*"
                  type="number"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please Enter SKU.',
                    },
                  }}
                />
              </Col>
              <Col className="col-md-auto">
                <AvField
                  name="qty"
                  label="QtY*"
                  type="number"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Please Enter Qty.',
                    },
                  }}
                />
              </Col>
              <Col className="col-md-auto">
                <Button type="submit" className="btn btn-dark mt-4">
                  Add
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>

      {cartItemClone?.length > 0 ? (
        <Card>
          <CardHeader>
            <h6>Customer Basket</h6>
          </CardHeader>
          <CardBody className="p-0">
            <div className="border-0">
              <table className="table table-hover mb-0 align-middle">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col"></th>
                    <th scope="col">Product</th>
                    <th scope="col">Price </th>
                    <th scope="col">Custom Price</th>
                    <th scope="col">QTY</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Total</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItemClone?.length > 0 ? (
                    cartItemClone?.map(function (item, index) {
                      if (item?.product?.coreFields?.itemMode === 2) {
                        setShowShippingControll(false);
                      }
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <img
                              alt=""
                              width="76"
                              height="76"
                              className="img-thumbnail"
                              src={item?.product?.mediaGallery?.[0]?.value}
                            />
                          </td>
                          <td className="product">
                            <p
                              className="m-0 fw-medium"
                              style={{ maxWidth: 230 }}
                            >
                              {item.product.name}{' '}
                              {selectedService ? ` - ${selectedService}` : null}
                            </p>
                            <p className="m-0 text-muted fs-11">
                              SKU : {item.sku}
                            </p>
                            {item?.sku === '990700100004' ? (
                              <p>
                                Service Name :{' '}
                                <SelectPicker
                                  data={serviceOptions}
                                  placeholder="Select an Service Type"
                                  onChange={handleSelectServiceType}
                                  required
                                />
                              </p>
                            ) : null}

                            {item?.product?.coreFields?.itemMode === 2 ? (
                              <p>
                                Item Type :
                                <span className="ps-2 text-danger fw-bold pe-3">
                                  Back to Back
                                </span>{' '}
                                Supplier name :{' '}
                                <span className="ps-2 text-danger fw-bold">
                                  {item?.product?.coreFields?.supplierInfo?.code.replace(
                                    /-/g,
                                    ' '
                                  )}
                                </span>
                              </p>
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="addCustomPrice">
                            <div className="d-flex align-items-center justify-content-start gap-1">
                              {countryInfo?.currency}{' '}
                              {item?.product?.defaultPrice?.specialPrice.toFixed(
                                2
                              ) !=
                              parseFloat(
                                item?.product?.selectedPrice?.specialPrice
                              ).toFixed(2) ? (
                                <>
                                  <p className="text-nowrap m-0 fw-medium">
                                    <span className="CrossText text-danger">
                                      {parseFloat(
                                        item?.product?.defaultPrice
                                          ?.specialPrice
                                      ).toFixed(2)}
                                    </span>
                                  </p>
                                </>
                              ) : (
                                ''
                              )}
                              <p className="text-nowrap m-0 fw-medium">
                                {isNaN(
                                  parseFloat(
                                    item?.product?.selectedPrice?.specialPrice
                                  )
                                )
                                  ? '0.00'
                                  : parseFloat(
                                      item?.product?.selectedPrice?.specialPrice
                                    ).toFixed(2)}
                              </p>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                name="customPriceEnable[]"
                                type="checkbox"
                                onChange={(e) =>
                                  customPriceCheckboxChange(e, item._id, index)
                                }
                                checked={customPrices[item._id] !== undefined}
                                id={'check_custom_' + item._id}
                              />
                              <label
                                className="form-check-label"
                                for={'check_custom_' + item._id}
                              >
                                Custom Price
                              </label>
                            </div>
                          </td>
                          <td>
                            {item?.product?.coreFields?.itemMode !== 2 ? (
                              <>
                                <input
                                  name="customPrice"
                                  required=""
                                  id={'price_custom_' + item?._id}
                                  type="text"
                                  value={
                                    item?.product?.selectedPrice?.specialPrice
                                  }
                                  className={`${
                                    !customPrices[item?._id] &&
                                    customPrices[item?._id] !== ''
                                      ? 'customPriceTxtClr'
                                      : 'customPriceTxtClrInvert'
                                  } form-control customPrice`}
                                  disabled={
                                    !customPrices[item?._id] &&
                                    customPrices[item?._id] !== ''
                                  }
                                  onChange={(e) => ChangeCustomPrice(e, index)}
                                  style={{ maxWidth: 120 }}
                                />
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            <input
                              name={`qty-${item?.sku}`}
                              required=""
                              id={`qty-${item?.sku}`}
                              type="text"
                              className="form-control qty"
                              value={item.qty}
                              onChange={(e) => ChangeCustomQty(e, index)}
                              style={{ maxWidth: 70 }}
                            />
                          </td>
                          <td>0.00</td>
                          <td>
                            <p className="text-nowrap mb-0 fw-medium">
                              {countryInfo?.currency}{' '}
                              {item?.product?.cart?.subTotal?.toFixed(2)}
                            </p>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end gap-2">
                              <Button
                                color="danger"
                                outline
                                onClick={() => removeItem(item?._id)}
                                className="fs-11 px-2 py-1"
                              >
                                Delete
                              </Button>{' '}
                              <Button
                                color="success"
                                type="button"
                                onClick={() => {
                                  updateCustomPrice(index);
                                }}
                                className="fs-11 px-2 py-1"
                              >
                                Update
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No items added
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
          <CardFooter>
            {Object.keys(cart).length > 0 ? (
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  color="dark"
                  className="fs-12"
                  onClick={() => {
                    setShowmapandAddress(true);
                  }}
                  disabled={disableNextToAddress}
                >
                  Continue
                </Button>
              </div>
            ) : (
              ''
            )}
          </CardFooter>
        </Card>
      ) : null}

      <Toaster position="top-right" reverseOrder={false} />
    </>
  ) : (
    ''
  );
}

export default CreateNewOrderComponent;
