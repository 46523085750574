import { useState, useEffect, button } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, Placeholder } from 'rsuite';
import { useHistory } from 'react-router';
import { useUrlSearchParams } from 'use-url-search-params';
import { orderBy } from 'lodash';
import { Badge } from 'reactstrap';
import android from '../../../assets/images/android.png';
import apple from '../../../assets/images/apple.png';
import huawei from '../../../assets/images/huawei.png';
import React from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from 'reactstrap';
import Places from '../../../components/maps/GoogleMaps';
import {
  updateCoordinate,
  getTriggerDMS,
  checkPayment,
  paymentInvoiceLink,
  getSellerInfo,
} from '../actions/orderActions';
import { nodeName } from 'rsuite/esm/DOMHelper';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function OrderDetailsBlock({ orderData, reLoadOrderDetail, username }) {
  console.log('userName ==' + username);
  let deviceType = '';
  const paymentLabel = {
    payfort: 'Payfort',
    payfort_fort_cc: 'Payfort',
    payfort_fort_installments: 'Payfort Installment',
    apple_pay: 'Apple Pay',
    payfort_omannet: 'Payfort - Oman Net',
    checkout: 'Checkout',
    tap: 'Tap',
    tap_apple_pay: 'Tap - Apple Pay',
    tap_careem_pay: 'Tap - Careem Pay',
    tap_knet: 'Tap KNET',
    cashew: 'Cashew',
    tamara: 'Tamara',
    spotii: 'Spotii',
    postpay: 'Postpay',
    oman_net: 'Oman Net',
    tabby: 'Tabby',
    ifin: 'Ifin',
    synapse: 'Synpase - By Bank',
    free: 'Free - Zero Checkout',
    benefit: 'Benefit',
  };
  const gateway = orderData?.Gateway;

  const walletAmount = orderData?.PaymentTenderDetails?.[0]?.WalletAmount;
  const walletNumber = orderData?.PaymentTenderDetails?.[0]?.WalletNumber;

  const [windowSize, setWindowSize] = useState(getWindowSize());
  let isMobmenu = 0;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (windowSize.innerWidth <= 720) {
    isMobmenu = 1;
  }

  const [dmsStsLoad, setDmsStsLoad] = useState(false);
  const [generateCheckPaymentLoad, setgenerateCheckPaymentLoad] =
    useState(false);
  const [generateInvoiceLoad, setgenerateInvoiceLoad] = useState(false);
  const [updatecoordinateLoad, setupdateCoordinateLoad] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isMap, setIsMap] = useState(false);
  const onMapClose = () => {
    setIsMap(false);
  };
  const onMapOpen = () => {
    setIsMap(true);
  };
  const [isBbB, setBBorderMode] = useUrlSearchParams({ order_mode: '' });

  const [isCoordinates, setCoordinates] = useState(false);
  const Latitude = orderData?.ShippingDetails?.[0]?.Latitude;
  const Longitude = orderData?.ShippingDetails?.[0]?.Longitude;
  const entity_id = orderData?.OrderId;
  if (Latitude === 0 && Longitude === 0) {
    setCoordinates(true);
  }

  const handleUpdateCoordinatesApi = (Latitude, Longitude) => {
    setupdateCoordinateLoad(true);
    updateCoordinate({
      params: {
        entity_id: entity_id,
        latitude: Latitude,
        longitude: Longitude,
      },
      dispatch,
    });
  };

  const handleTriggerDMS = (country, reference_no) => {
    setDmsStsLoad(true);
    getTriggerDMS({
      params: {
        country: country,
        entity_id: entity_id,
        reference_no: reference_no,
      },
      dispatch,
    });
  };

  const handlecheckPayment = (
    reference_no,
    gateway,
    entity_id,
    country,
    device,
    transaction_id
  ) => {
    setgenerateCheckPaymentLoad(true);

    checkPayment({
      params: {
        reference_no: reference_no,
        gateway: gateway,
        entity_id: entity_id,
        country: country,
        device: device,
        transaction_id: transaction_id === null ? '' : transaction_id,
      },
      dispatch,
    });
  };
  const handlepaymentInvoiceLink = (reference_no, gateway, country, device) => {
    setgenerateInvoiceLoad(true);

    paymentInvoiceLink({
      params: {
        reference_no: reference_no,
        gateway: gateway,
        country: country,
        device: device,
      },
      dispatch,
    });
  };

  const { updateLatLng = [], isLoading } = useSelector((state) => state.order);
  const { dms = [], dmsLoading } = useSelector((state) => state.order);

  const { coordinates = [], coordinateLoading } = useSelector(
    (state) => state.order
  );
  const { checkpayment = [], ischeckPaymentLoading } = useSelector(
    (state) => state.order
  );
  const { paymentInvoice = [], ischeckpaymentInvoiceLoading } = useSelector(
    (state) => state.order
  );
  const deliveryAdditionalInfo = orderData?.deliveryAdditionalInfo
    ? JSON.parse(orderData?.deliveryAdditionalInfo)
    : '';
  const deliveryMode = orderData?.DeliveryMode;
  let delivMode = '';
  if (deliveryMode === '0') {
    delivMode = 'Courier';
  } else if (deliveryMode === '1') {
    delivMode = 'Warehouse';
  } else if (deliveryMode === '2') {
    delivMode = 'BackToBack';
  } else if (deliveryMode === '3') {
    delivMode = 'Warehouse + BackToBack';
  } else if (deliveryMode === '4') {
    delivMode = 'Courier + BackToBack';
  } else if (deliveryMode === '5') {
    delivMode = 'Warehouse + BackToBack + Courier';
  } else {
    delivMode = 'Warehouse + Courier';
  }

  useEffect(() => {
    setgenerateCheckPaymentLoad(false);
    if (checkpayment?.code === 100) {
      toast.success(checkpayment?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      reLoadOrderDetail();
    } else {
      if (checkpayment?.message) {
        toast.error(checkpayment?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [checkpayment]);
  useEffect(() => {
    setgenerateInvoiceLoad(false);
    if (paymentInvoice?.code === 100) {
      toast.success(paymentInvoice?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      reLoadOrderDetail();
    } else {
      if (paymentInvoice?.message) {
        toast.error(paymentInvoice?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [paymentInvoice]);
  useEffect(() => {
    setDmsStsLoad(false);
    if (dms?.code === 100) {
      toast.success(dms?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      reLoadOrderDetail();
    } else {
      if (dms?.message) {
        toast.error(dms?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [dms]);
  useEffect(() => {
    if (coordinates?.code === 100) {
      setupdateCoordinateLoad(false);
      toast.success(coordinates?.message, {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });
      reLoadOrderDetail();
    } else {
      if (coordinates?.message) {
        toast.error(coordinates?.message, {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [coordinates]);
  useEffect(() => {
    if (updateLatLng?.code === 100) {
      setIsMap(false);
    }
  }, [updateLatLng]);

  const renderPaymentDetils = (payData) => {
    let lsOrderSts = '';
    if (
      payData.LsStatus === '0' ||
      payData.LsStatus === '' ||
      payData.LsStatus === null
    ) {
      lsOrderSts = payData.LsStatus;
    } else {
      lsOrderSts = payData.LsStatus.toLowerCase().trim();
    }
    const paymentMode = payData?.PaymentMode;
    const paymentGateway = payData?.Gateway;
    const paymentStatus = payData?.FinancialStatus;

    if (paymentMode === 'cashondelivery' && walletAmount == '0.00') {
      return (
        <div className="paymentDetailSec">
          <label>
            Payment Provider : <b>Cash on Delivery</b>
          </label>
          <label>
            Status : <b className="successBtn">Success</b>
          </label>
          <label>
            COD Fee :{' '}
            <b>
              {orderData?.Currency} {orderData?.COD_charges}
            </b>
          </label>
        </div>
      );
    } else {
      return (
        <div className="paymentDetailSec">
          <label>
            Gateway :
            {gateway === 'Zero Checkout' && walletAmount > 0 ? (
              <b>Wallet</b>
            ) : gateway === 'Cash On Delivery' && walletAmount > 0 ? (
              <b>Wallet + cod </b>
            ) : gateway === 'Credit/Debit Card' && walletAmount > 0 ? (
              <b>Wallet + Credit card</b>
            ) : (
              <b>{paymentLabel[paymentMode]} </b>
            )}
          </label>
          <label>
            Status :{' '}
            <b className={paymentStatus === '1' ? 'successBtn' : 'failedBtn'}>
              {paymentStatus === '1' ? 'Success' : 'Failed'}
            </b>
          </label>
          <label>
            {' '}
            Trans ID :
            {walletAmount > 0 ? (
              <b>{walletNumber}</b>
            ) : (
              <>
                <b>{orderData?.TransactionId}</b>
              </>
            )}
          </label>
          {paymentStatus === '0' && orderData?.Status !== 'canceled' ? (
            <>
              <div id="outer">
                <div className="inner">
                  <button
                    type="button"
                    className="btn btn-outline-dark btn-sm waves-effect waves-light "
                    onClick={() =>
                      handlecheckPayment(
                        orderData?.OrderNo,
                        orderData?.PaymentMode,
                        orderData?.OrderId,

                        orderData?.ShippingDetails?.[0]?.Country,
                        orderData?.Device,
                        orderData?.TransactionId
                      )
                    }
                  >
                    {' '}
                    {generateCheckPaymentLoad === true && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    Check Payment Detail{' '}
                  </button>
                </div>
                {orderData?.CouponCode !== 'AED11' ? (
                  <div className="inner">
                    <button
                      type="button"
                      className="btn btn-outline-dark btn-sm waves-effect waves-light"
                      onClick={() =>
                        handlepaymentInvoiceLink(
                          orderData?.OrderNo,
                          orderData?.PaymentMode,
                          orderData?.ShippingDetails?.[0]?.Country,
                          orderData?.Device
                        )
                      }
                    >
                      {' '}
                      {generateInvoiceLoad === true && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{' '}
                      Regenerate Payment Link{' '}
                    </button>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    }
  };

  const renderShippingDetails = (shippingData) => {
    let shippingMethodvalue = '';
    const ShippingMethod = shippingData?.ShippingMethod;
    if (ShippingMethod === 'clickandcollect') {
      shippingMethodvalue = 'Click and Collect';
    } else if (ShippingMethod === 'giftcard') {
      shippingMethodvalue = 'Gift Card';
    } else {
      shippingMethodvalue = 'Bestway';
    }

    const ShippingCharge = shippingData?.ShippingCharge;
    if (ShippingMethod === 'bestway') {
      return (
        <div className="paymentDetailSec">
          <label>
            Shipping Fee: {orderData?.Currency} {ShippingCharge}
          </label>

          {orderData?.isDms === '0' &&
          orderData?.FinancialStatus === '1' &&
          shippingData?.ShippingDetails?.[0]?.Country.toLowerCase() === 'ae' &&
          isBbB?.order_mode !== 'b2b' &&
          isBbB?.order_mode === 'split' &&
          shippingData?.ShippingMethod !== 'clickandcollect' &&
          orderData?.ShipmentDetails?.length <= 1 &&
          (orderData?.ShipmentDetails?.[0]?.ShipmentItems?.[0]?.DeliveryDate ===
            '' ||
            orderData?.ShipmentDetails?.[0]?.ShipmentItems?.[0]
              ?.DeliveryDate === null) &&
          orderData?.LsStatus !== 'Cancel' &&
          orderData?.IsFlowLs === '0' &&
          orderData?.IsSuccessOrder === '1' ? (
            <>
              <button
                type="button"
                className="btn btn-dark btn-sm waves-effect waves-light"
                onClick={() =>
                  handleTriggerDMS(
                    shippingData?.ShippingDetails?.[0]?.Country,
                    orderData?.OrderNo
                  )
                }
              >
                {' '}
                {dmsStsLoad === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{' '}
                Trigger DMS
              </button>
            </>
          ) : (
            <>
              {orderData?.isDms === '0' &&
              orderData?.Country === 'om' &&
              orderData?.FinancialStatus === '1' &&
              (orderData?.DeliveryDate === '' ||
                orderData?.DeliveryDate === null) &&
              orderData?.LsStatus !== 'Cancel' &&
              orderData?.IsSuccessOrder === '1' ? (
                <>
                  <button
                    type="button"
                    className="btn btn-dark btn-sm waves-effect waves-light"
                    onClick={() =>
                      handleTriggerDMS(
                        shippingData?.ShippingDetails?.[0]?.Country,
                        orderData?.OrderNo
                      )
                    }
                  >
                    {' '}
                    {dmsStsLoad === true && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{' '}
                    Trigger DMS
                  </button>
                </>
              ) : orderData?.DeliveryDate ? (
                <>
                  <label>
                    Delivery Date: {orderData?.DeliveryDate}, Slot :{' '}
                    {orderData?.DeliveryTimeSlot && orderData?.DeliveryTimeSlot}
                  </label>
                </>
              ) : null}
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="paymentDetailSec ">
          <div className="shippingMethodStyle mb-2">
            <i className="bx bx-store" id="icon"></i>{' '}
            <span id="text">{shippingMethodvalue}</span>
          </div>
          <br />
          {orderData?.ShippingMethod === 'mageworxpickup' ? (
            <div>
              <label>
                {orderData?.ShippingDetails?.[0]?.Address1} <br />{' '}
              </label>

              <br />
            </div>
          ) : (
            <>
              <label>{orderData?.ShippingDetails?.[0]?.Address1}</label>
              <label>{orderData?.ShippingDetails?.[0]?.Address2}</label>
            </>
          )}

          {orderData?.DeliverBy !== '' ? (
            <label>
              <b>DeliverBy:</b>

              {orderData?.DeliverBy}
            </label>
          ) : (
            ''
          )}
          {orderData?.isDms === '0' &&
          orderData?.ShippingMethod !== 'mageworxpickup' &&
          shippingData?.ShippingMethod !== 'clickandcollect' &&
          shippingData?.ShippingMethod !== 'giftcard' ? (
            <div className="paymentDetailSec ">
              <label
                className="status failed"
                style={{ float: 'left', top: 10, left: 0 }}
              >
                <b>DMS Delivery date Missing </b>
              </label>
              <button
                type="button"
                className="btn btn-dark btn-sm waves-effect waves-light"
                onClick={() =>
                  handleTriggerDMS(
                    shippingData?.country,
                    orderData?.reference_no
                  )
                }
              >
                {' '}
                {dmsStsLoad === true && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{' '}
                Trigger DMS
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
  };

  const renderDeviceDetails = (deviceData) => {
    const deviceObj = {};
    deviceData?.split('||').forEach((item) => {
      const [key, value] = item.split(':');
      deviceObj[key?.toLowerCase()] = value;
    });
    let browserType = '';
    let osData = '';
    let deData = '';
    let appVersion = '';
    const StoreInfo = orderData?.StoreInfo
      ? JSON.parse(orderData?.StoreInfo)
      : null;

    deviceType = deviceObj['devicetype'] ? deviceObj['devicetype'] : '';

    if (!!deviceObj['appversion'] && deviceObj['appversion'] !== 'undefined') {
      appVersion = deviceObj['appversion'];
    }

    if (
      deviceObj['device'] === '' ||
      deviceObj['device'] === 'web' ||
      deviceObj['device'] === 'Desktop' ||
      deviceObj['device'] === 'Mobile'
    ) {
      //its not a app
      browserType = 'Web';
      deData = 'mdi mdi-laptop-windows';
      if (deviceObj['device'] === 'Mobile') {
        //mobile browser
        browserType = 'Mobile Web';
        deData = 'mdi mdi-cellphone';
        if (deviceObj['os'].toLowerCase() === 'android') {
          osData = 'mdi mdi-android-debug-bridge';
        } else if (deviceObj['os']?.toLowerCase() === 'ios') {
          osData = 'mdi mdi-apple';
        }
      } else {
        //desktop browser
        if (deviceObj['os'] === 'Windows') {
          osData = 'mdi mdi-microsoft-windows';
        } else if (deviceObj['os'] === 'Mac OS') {
          osData = 'mdi mdi-apple';
        } else if (deviceObj['os'] === 'Linux') {
          osData = 'mdi mdi-linux';
        } else {
          osData = 'mdi mdi-web';
        }
      }
    } else {
      //its a mobile App
      browserType = 'App';
      if (deviceObj['os']?.toLowerCase() === 'ios') {
        osData = 'mdi mdi-apple';
      } else {
        osData = 'mdi mdi-android-debug-bridge';
      }
    }

    if (StoreInfo?.staffId) {
      browserType = 'Kiosk App';
      deviceObj['device'] = 'Kiosk';
    }
    return (
      <div>
        Device Details - Browser Type : <b>{browserType}</b> | OS :
        <i className={osData}></i>{' '}
        <b>
          {deviceObj['os']}{' '}
          {deviceObj['devicetype'] === 'huawei' ? '- huawei' : ''}
        </b>{' '}
        | Device : <i className={deData}></i> <b>{deviceObj['device']}</b>{' '}
        {appVersion ? (
          <>
            | App Version :<b>{appVersion}</b>
          </>
        ) : null}
        {username ? (
          <>
            {' | Created By :'} <b>{username}</b>
          </>
        ) : null}
        {StoreInfo?.staffId ? (
          <>
            | Store Info :{' '}
            <b>
              {StoreInfo?.storeCode} - {StoreInfo?.staffId}
            </b>
          </>
        ) : null}
      </div>
    );
  };
  return (
    <>
      <div className="deviceDataBlock">
        <>{renderDeviceDetails(orderData?.DeviceTracking)}</>
      </div>
      <Row className="row-fluid-scroll">
        <Col md={6} xl={4}>
          <Card className="OrderDetailsBox card-h-100">
            <CardHeader className="bg-light">
              <CardTitle>CUSTOMER DETAILS</CardTitle>
            </CardHeader>
            {orderData !== undefined ? (
              <div className="paymentDetailSec">
                <CardBody>
                  <label>
                    <strong>{orderData?.CustomerName}</strong>
                  </label>
                  <label>{orderData?.ShippingDetails?.[0]?.Address1}</label>
                  <label>{orderData?.ShippingDetails?.[0]?.Address2}</label>
                  <label>
                    Building :{' '}
                    {orderData?.ShippingDetails?.[0]?.apartmentDetails}
                  </label>
                  <label>
                    Region :{orderData?.ShippingDetails?.[0]?.Region}
                  </label>
                  <label>Email : {orderData?.Email}</label>
                  <label>
                    Phone : {orderData?.ShippingDetails?.[0]?.Telephone}
                  </label>
                  {orderData?.ShippingDetails?.[0]?.alternatePhone ? (
                    <label>
                      Alternate Phone :{' '}
                      {orderData?.ShippingDetails?.[0]?.alternatePhone}
                    </label>
                  ) : (
                    ''
                  )}

                  <a
                    href={`https://www.google.com/maps?q=${Latitude},${Longitude}`}
                    className="btn btn-dark btn-sm"
                    target="_blank"
                  >
                    {/* {orderData?.ShippingDetails?.[0]?.Latitude}, {orderData?.ShippingDetails?.[0]?.Longitude} */}
                    View Location Map
                  </a>
                  {isCoordinates ? (
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={onMapOpen}
                    >
                      {' '}
                      {coordinateLoading === true && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{' '}
                      Update Coordinates
                    </button>
                  ) : (
                    ''
                  )}
                </CardBody>
              </div>
            ) : (
              <>
                <CardBody>
                  <Skeleton count={8} />
                </CardBody>
              </>
            )}
          </Card>
        </Col>
        <Col md={6} xl={4}>
          <Card className=" OrderDetailsBox card-h-100">
            <CardHeader className="bg-light">
              <CardTitle>PAYMENT DETAILS</CardTitle>
            </CardHeader>
            <CardBody>
              {orderData !== undefined ? (
                <>{renderPaymentDetils(orderData)}</>
              ) : (
                <>
                  <Skeleton count={5} />
                </>
              )}

              <label>
                Order Placed From:
                {orderData?.Device.toLowerCase() === 'web' ||
                orderData?.Device.toLowerCase() === 'desktop' ? (
                  <>
                    {' '}
                    <Badge className="bg-soft-dark text-dark">Website</Badge>
                  </>
                ) : (
                  ''
                )}
                {(orderData?.Device.toLowerCase() === 'phone' ||
                  orderData?.Device.toLowerCase() === 'mobile') &&
                orderData?.Os.toLowerCase() === 'android' ? (
                  deviceType === 'huawei' ? (
                    <>
                      <b>Android - {deviceType} </b>
                      <img
                        src={huawei}
                        alt=""
                        height="20"
                        className="mb-1"
                      />{' '}
                    </>
                  ) : (
                    <>
                      <b>Android</b>
                      <img
                        src={android}
                        alt=""
                        height="20"
                        className="mb-1"
                      />{' '}
                    </>
                  )
                ) : null}
                {(orderData?.Device.toLowerCase() === 'phone' ||
                  orderData?.Device.toLowerCase() === 'mobile') &&
                orderData?.Os.toLowerCase() === 'ios' ? (
                  <>
                    <b>iOS</b>
                    <img src={apple} alt="" height="20" className="mb-1" />{' '}
                  </>
                ) : null}
              </label>
            </CardBody>
          </Card>
        </Col>

        <Col md={6} xl={4}>
          <Card className=" OrderDetailsBox card-h-100">
            <CardHeader className="bg-light">
              <CardTitle>SHIPPING DETAILS</CardTitle>
            </CardHeader>
            <CardBody>
              {orderData !== undefined ? (
                <>{renderShippingDetails(orderData)}</>
              ) : (
                <>
                  <Skeleton count={5} />
                </>
              )}

              {orderData?.DeliveryLocn !== '' &&
              orderData?.ShippingMethod !== 'clickandcollect' &&
              orderData?.ShippingMethod !== 'giftcard' ? (
                <div className="paymentDetailSec">
                  <label>
                    Delivered Type:{' '}
                    <span
                      style={{
                        padding: ' 0 3px',
                        backgroundColor: '#f7ecb6',
                        color: 'black',
                        border: '#dfb950 solid 1px',
                        borderRadius: '3px',
                      }}
                    >
                      {delivMode}
                    </span>
                  </label>
                  {orderData?.ItemMode === '2' ? (
                    <label>
                      {' '}
                      {orderData?.DeliverBy === 'IMILE' ||
                      orderData?.DeliverBy === 'TFM' ||
                      orderData?.DeliverBy === 'TFM - Bulky' ? (
                        <>
                          Delivered By: <b>{orderData?.DeliverBy}</b>
                        </>
                      ) : orderData?.DeliverBy === '3PL' ? (
                        <>
                          Delivered By: <b>Emirates Post</b>
                        </>
                      ) : orderData?.DeliverBy === 'TFM' ||
                        orderData?.DeliverBy === 'TFM - Bulky' ? (
                        <>
                          Delivered By: <b>TFM</b>
                        </>
                      ) : (
                        <>
                          Delivered By: <b>Self Ship</b>
                        </>
                      )}
                    </label>
                  ) : null}

                  {orderData?.Awb_number ? (
                    <label>
                      Awb Number: <span>{orderData?.Awb_number}</span>
                    </label>
                  ) : null}
                </div>
              ) : null}
              {orderData?.zoneId ? (
                <div className="paymentDetailSec">
                  <label>
                    Zone Id :{' '}
                    <span
                      style={{
                        padding: ' 0 3px',
                        backgroundColor: '#f7ecb6',
                        color: 'black',
                        border: '#dfb950 solid 1px',
                        borderRadius: '3px',
                        fontWeight: 'bold',
                      }}
                    >
                      {orderData?.zoneId}
                    </span>
                  </label>
                </div>
              ) : null}
            </CardBody>
            {deliveryAdditionalInfo && (
              <>
                <CardHeader className="bg-light">
                  <CardTitle>DELIVERY ADDITIONAL INFO</CardTitle>
                </CardHeader>
                <CardBody>
                  <table>
                    <tr className="lh-1">
                      <label>
                        <td>Driver Name</td>
                        <td className="ps-2">
                          {' '}
                          :{' '}
                          {deliveryAdditionalInfo.driverName
                            ? deliveryAdditionalInfo.driverName
                            : 'N/A'}
                        </td>
                      </label>
                    </tr>
                    <tr className="lh-1">
                      <label>
                        <td>Driver Number </td>
                        <td className="ps-2">
                          {' '}
                          :{' '}
                          {deliveryAdditionalInfo.driverNumber
                            ? deliveryAdditionalInfo.driverNumber
                            : 'N/A'}
                        </td>
                      </label>
                    </tr>
                  </table>
                </CardBody>{' '}
              </>
            )}
          </Card>
        </Col>
      </Row>
      {isMap ? (
        <>
          <Row style={{ zIndex: 990 }}>
            <div>
              <button
                type="button"
                className="btn btn-danger btn-sm waves-effect waves-light"
                onClick={onMapClose}
                style={{ float: 'right', top: 30, right: 30, zIndex: 999 }}
              >
                Close
              </button>
            </div>
            <Col xl={4} md={6}>
              <Places handleUpdateCoordinatesApi={handleUpdateCoordinatesApi} />
            </Col>
          </Row>
        </>
      ) : null}
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}
export default OrderDetailsBlock;
