import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: true,
  isDataLoading: true,
  walletList: [],
  walletBalanceInfo: {},
  walletTransactionList: [],
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletList(state, actions) {
      state.walletList = actions.payload;
      state.isLoading = false;
    },
    setWalletBalance(state, actions) {
      state.walletBalanceInfo = actions.payload;
      state.isLoading = false;
    },
    setWalletTransactionList(state, actions) {
      state.walletTransactionList = actions.payload;
      state.isDataLoading = false;
    },
  },
});

export const { setWalletList, setWalletBalance, setWalletTransactionList } =
  walletSlice.actions;
const walletReducer = walletSlice.reducer;
export default walletReducer;
