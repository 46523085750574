import axios from 'axios';
import {
  setWalletBalance,
  setWalletList,
  setWalletTransactionList,
} from '../../../Redux/wallet/walletSlice';

const walletApiUrl = process.env.REACT_APP_WALLET_API;
const walletAuthToken = process.env.REACT_APP_WALLET_API_KEY;

//Get Wallet List
export const getWalletList = async (params, dispatch) => {
  const config = {
    method: 'POST',
    url: `${walletApiUrl}/oms/wallet/list`,
    headers: {
      Authorization: `Bearer ${walletAuthToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setWalletList(response?.data?.data));
    return response?.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setWalletList(null));
    } else {
    }
  }
};

//Get Wallet Balance
export const getWalletBalanceInfo = async (params, dispatch) => {
  const config = {
    method: 'POST',
    url: `${walletApiUrl}/oms/wallet/balance`,
    headers: {
      Authorization: `Bearer ${walletAuthToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setWalletBalance(response?.data?.data));
    return response?.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setWalletBalance(null));
    } else {
    }
  }
};

//get wallet transaction list
export const getWalletTransactions = async (params, dispatch) => {
  const config = {
    method: 'POST',
    url: `${walletApiUrl}/oms/transaction/list`,
    headers: {
      Authorization: `Bearer ${walletAuthToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setWalletTransactionList(response?.data?.data));
    return response?.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      dispatch(setWalletTransactionList(null));
    } else {
    }
  }
};
