import React, { useEffect, useState } from 'react';
import ReactDrawer from 'react-drawer';
import './AskDeliveryHelpQuestion.scss';
import { useLocation } from 'react-router-dom';
import { getQuestionAnswer, insertOrderShipQustionAnswer } from './action';
import qA from '../../../../assets/Group_175769.svg';
import { Link, BrowserRouter } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { Button, Row, Col } from 'reactstrap';
function ViewQuestionDetail({ shipment, openPopup, order_id, split, orderCn }) {
  console.log(openPopup, 'openPopup');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showPop = queryParams.get('showPopup');
  const [position, setPosition] = useState();
  const [qAnsData, setqAnsData] = useState([]);
  const [open, setOpen] = useState(openPopup);
  const [isLoading, setIsLoading] = useState(false);
  const [textBoxOpen, setTextBoxOpen] = useState(false);
  const [replyQuestionIndex, setReplyQuestionIndex] = useState('');

  const [askQues, setAskQues] = useState('');
  let userID = '';

  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userID = userObj?.user_id;
    }
  }
  useEffect(() => {
    setTimeout(() => {
      if (openPopup === 'true') {
        setOpen(true);
        getAnsQuesFunc();
      }
    }, 100);
  }, [openPopup]);
  // Clone the original array and sort it

  const toggleRightDrawer = () => {
    setPosition('right');
    setOpen(!open);
  };
  const onDrawerClose = () => {
    setOpen(false);
  };
  const getAnsQuesFunc = async () => {
    let data = {
      reference_no: shipment,
    };

    let result = await getQuestionAnswer(data);

    if (result) {
      setqAnsData(result);
    }
  };
  const askQuestionFunc = async (shipment) => {
    setIsLoading(true);
    let isSuccess = await insertOrderShipQustionAnswer({
      params: {
        qa_description: askQues,
        reference_no: shipment,
        qa_type: '2',
        created_by: 'admin',
        parent_id: replyQuestionIndex,
        is_replied: '1',
        created_by_id: userID,
      },
    });

    if (isSuccess) {
      setAskQues('');
      setTextBoxOpen(false);
      getAnsQuesFunc();
    }
  };

  return (
    <>
      <div
        className="viewQueries mt-3"
        onClick={() => {
          toggleRightDrawer();
          getAnsQuesFunc();
        }}
      >
        <span>
          <img src={qA} />
          View all shipment queries
        </span>
      </div>

      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <div className="askHelpPopup">
          <div className="askHelpContain">
            <div className="askHelpSection">
              <div className="askHelpTitle">
                <h3>Ask a Help?</h3>
              </div>
              <div className="askHelpView">
                <BrowserRouter forceRefresh={true}>
                  <Link
                    to={`/orders/${order_id}/?order_mode=${split}&orderCn=${orderCn}`}
                    className="btn btn-sm btn-outline-warning waves-effect waves-light mr-2"
                  >
                    <i className="mdi mdi-eye-outline"></i>{' '}
                  </Link>
                </BrowserRouter>
              </div>
            </div>
            <div className="askHelpMessageContain">
              <div className="askHelpRefId">
                Looking Help for Shipping Ref : {shipment}
              </div>
              {qAnsData?.map((qa) => {
                let qaTag = '';
                let stsBtn = 'bg-soft-dark text-dark';
                if (qa?.created_by === 'customer') {
                  stsBtn = 'bg-warning';

                  qaTag = 'C';
                } else if (qa?.created_by === 'seller') {
                  stsBtn = 'bg-primary';
                  qaTag = 'S';
                } else {
                  stsBtn = 'bg-danger';
                  qaTag = 'D';
                }
                return (
                  <>
                    <div className="askHelpMessage">
                      <div className="customerMessage">
                        <div className="icon">
                          <Badge className={` ${stsBtn} me-3`}>{qaTag}</Badge>
                        </div>
                        <div className="contentData">
                          <div
                            className={`message ${
                              qa?.is_replied === '1' ? 'read' : ''
                            } `}
                          >
                            <p className="time">
                              <b> {qa?.created_at}</b>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.435"
                                height="8.162"
                                viewBox="0 0 14.435 8.162"
                              >
                                <g
                                  id="Group_175571"
                                  data-name="Group 175571"
                                  transform="translate(-49.999 -2.418)"
                                >
                                  <path
                                    id="Path_85078"
                                    data-name="Path 85078"
                                    d="M77.953,4.3a.721.721,0,0,1,.474-.618.665.665,0,0,1,.638.158c.856.865,1.72,1.721,2.577,2.585.1.1.152.115.26.007Q85.039,3.289,88.182.149a.654.654,0,0,1,1.032.288.57.57,0,0,1-.145.609q-.829.843-1.657,1.687l-1.561,1.59-1.6,1.633c-.524.534-1.052,1.064-1.569,1.6a1.865,1.865,0,0,1-.616.522.6.6,0,0,1-.772-.161c-.41-.429-.837-.841-1.254-1.263Q79.222,5.833,78.412,5a2.435,2.435,0,0,1-.3-.334,2.186,2.186,0,0,1-.161-.365"
                                    transform="translate(-24.813 2.418)"
                                  />
                                  <path
                                    id="Path_85079"
                                    data-name="Path 85079"
                                    d="M167.264,1.552a.574.574,0,0,1,.633.411.6.6,0,0,1-.166.677q-1.695,1.686-3.381,3.38a.63.63,0,0,1-1.081-.271.6.6,0,0,1,.163-.623q1.7-1.7,3.4-3.4a.471.471,0,0,1,.428-.174"
                                    transform="translate(-106.664 0.936)"
                                  />
                                  <path
                                    id="Path_85080"
                                    data-name="Path 85080"
                                    d="M3.79,96.716a.529.529,0,0,1-.407-.178Q1.792,94.95.206,93.358a.591.591,0,0,1-.021-.88A.61.61,0,0,1,1.02,92.4a1.344,1.344,0,0,1,.182.151q1.521,1.518,3.04,3.039a.644.644,0,0,1-.277,1.1,1.173,1.173,0,0,1-.174.027"
                                    transform="translate(50 -86.137)"
                                  />
                                </g>
                              </svg>
                            </p>
                            <p>{qa?.description}</p>
                          </div>
                          <div className="rply">
                            {qa?.answers.length === 0 ? (
                              <span
                                className="rplyButton"
                                onClick={() => {
                                  setTextBoxOpen(true);
                                }}
                              >
                                Reply
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      {qa?.answers?.map((ans) => {
                        let qaTag = '';
                        let stsBtn = 'bg-soft-dark text-dark';
                        if (ans?.created_by === 'customer') {
                          stsBtn = 'bg-warning';

                          qaTag = 'C';
                        } else if (ans?.created_by === 'seller') {
                          stsBtn = 'bg-primary';
                          qaTag = 'S';
                        } else {
                          stsBtn = 'bg-danger';
                          qaTag = 'D';
                        }
                        return (
                          <div className="replayMessage">
                            <div className="contentData">
                              <div
                                className={`message ${
                                  qa?.is_replied === '1' ? 'read' : ''
                                } `}
                              >
                                <p className="time">
                                  <b>{ans?.created_at}</b>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.435"
                                    height="8.162"
                                    viewBox="0 0 14.435 8.162"
                                  >
                                    <g
                                      id="Group_175571"
                                      data-name="Group 175571"
                                      transform="translate(-49.999 -2.418)"
                                    >
                                      <path
                                        id="Path_85078"
                                        data-name="Path 85078"
                                        d="M77.953,4.3a.721.721,0,0,1,.474-.618.665.665,0,0,1,.638.158c.856.865,1.72,1.721,2.577,2.585.1.1.152.115.26.007Q85.039,3.289,88.182.149a.654.654,0,0,1,1.032.288.57.57,0,0,1-.145.609q-.829.843-1.657,1.687l-1.561,1.59-1.6,1.633c-.524.534-1.052,1.064-1.569,1.6a1.865,1.865,0,0,1-.616.522.6.6,0,0,1-.772-.161c-.41-.429-.837-.841-1.254-1.263Q79.222,5.833,78.412,5a2.435,2.435,0,0,1-.3-.334,2.186,2.186,0,0,1-.161-.365"
                                        transform="translate(-24.813 2.418)"
                                      />
                                      <path
                                        id="Path_85079"
                                        data-name="Path 85079"
                                        d="M167.264,1.552a.574.574,0,0,1,.633.411.6.6,0,0,1-.166.677q-1.695,1.686-3.381,3.38a.63.63,0,0,1-1.081-.271.6.6,0,0,1,.163-.623q1.7-1.7,3.4-3.4a.471.471,0,0,1,.428-.174"
                                        transform="translate(-106.664 0.936)"
                                      />
                                      <path
                                        id="Path_85080"
                                        data-name="Path 85080"
                                        d="M3.79,96.716a.529.529,0,0,1-.407-.178Q1.792,94.95.206,93.358a.591.591,0,0,1-.021-.88A.61.61,0,0,1,1.02,92.4a1.344,1.344,0,0,1,.182.151q1.521,1.518,3.04,3.039a.644.644,0,0,1-.277,1.1,1.173,1.173,0,0,1-.174.027"
                                        transform="translate(50 -86.137)"
                                      />
                                    </g>
                                  </svg>
                                </p>
                                <p>{ans?.description}</p>
                              </div>
                            </div>
                            <div className="icon">
                              <Badge className={` ${stsBtn} me-3`}>
                                {qaTag}
                              </Badge>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr className="breaker"></hr>
                  </>
                );
              })}
            </div>
            {textBoxOpen ? (
              <div
                className="askHelpTextArea"
                onClick={() => {
                  setReplyQuestionIndex(qAnsData?.[0]?.qa_id);
                }}
              >
                <textarea
                  value={askQues}
                  onChange={(e) => {
                    setAskQues(e.target.value);
                  }}
                  placeholder="Please Enter Your Queries"
                  className="form-control"
                  rows={5}
                  cols={5}
                />
                <div className="sendBtn">
                  <span
                    className="btn btn-dark mt-3 textAlignRight sendBtn"
                    onClick={() => {
                      askQuestionFunc(shipment);
                    }}
                  >
                    Submit
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </ReactDrawer>
    </>
  );
}

export default ViewQuestionDetail;
