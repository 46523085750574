import { useState, useEffect, useSearchParams, useRef } from 'react';
import { MetaTags } from 'react-meta-tags';
import { DashBreadcrumb } from '../../../components/Common/Breadcrumb';
import 'react-drawer/lib/react-drawer.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'flatpickr/dist/themes/material_blue.css';
import ApolloClientKnowledgeBaseService from '../../../ApolloClientKBService';
import { GET_CATEGORY_DATA_DETAIL } from '../../Customers/query/GetCategoryDetail';
import { UPDATE_CATEGORY } from '../../Customers/query/updateCategoryDetail';
import { useParams, Link } from 'react-router-dom';
import drapIcon from '../../../assets/images/Group 174067.svg';
import dropIcon from '../../../assets/images/Group 174068.svg';
import { GET_DEPARTMENT_NAME_LIST } from '../../Customers/query/DepartmentListName';

import {
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Modal,
  Spinner,
  FormGroup,
} from 'reactstrap';
import { GET_ARTICLES_NAME_LIST } from '../../Customers/query/CategoryArticleList';
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { Badge } from 'reactstrap';
import { GET_ARTICLES_DATA_POSITION } from '../../Customers/query/ArticlePosition';
import { ListItem } from 'semantic-ui-react';
const KbUpdateCategoryForm = () => {
  const { id } = useParams();
  const [kbCreateCatData, setCreateCatData] = useState('');
  const [saveCatLoad, setCatLoad] = useState(false);
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [articleType, setArticleType] = useState('');
  const [getArtNameList, setGetArtNameList] = useState('');
  const [kbCatData, setKbCatData] = useState('');
  const [modal_center, setmodal_center] = useState(false);
  const [value, setValue] = useState('');
  const [articleTypeVal, setArticleTypeVal] = useState('');
  const [toggleValue, setToggleValue] = useState('Select Status');
  const [articalTreeVal, setArticalsTreeData] = useState([]);
  const [itemList, setItemList] = useState();
  const [artList, setArtList] = useState();
  const [selectedGroup, setselectedGroup] = useState(null);
  const [kabCatError, setKbCatError] = useState('');

  const [getDepName, setDepNameList] = useState();
  const [depValue, setDepValue] = useState('');

  const [apiDepValue, setApiDepValue] = useState('');
  const [kabArtPosition, setKbArtPositionData] = useState('');
  const [articalDefaultTreeVal, setDefaultArticalsTreeData] = useState([]);

  const buttonRef = useRef(null);
  const kbArtPositionData = async () => {
    let posData = [];

    itemList?.map((item) => {
      let obj = {
        _id: item?.value,
        position: item?.position,
      };
      posData?.push(obj);
    });

    try {
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_ARTICLES_DATA_POSITION,
        variables: {
          input: { positionData: posData },
        },
        fetchPolicy: 'no-cache',
      });
      setKbCatData(data);
    } catch (error) {}
  };
  useEffect(() => {
    setItemList(articalTreeVal);
  }, [articalTreeVal]);
  useEffect(() => {
    if (value === 0) {
      setToggleValue('Unpublished');
    } else if (value === 1) {
      setToggleValue('Published');
    } else if (value === 2) {
      setToggleValue('Draft');
    } else {
      setToggleValue('Select Status');
    }
  }, [value]);
  const handleArticleType = (e) => {
    setArticleType(e.target.value);
  };

  function tog_center() {
    setmodal_center(!modal_center);
  }
  const handleSelect = (e) => {
    setValue(e);
  };

  const kbCategoryData = async () => {
    try {
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_CATEGORY_DATA_DETAIL,
        variables: {
          input: {
            _id: id,
          },
        },
        fetchPolicy: 'no-cache',
      });
      setKbCatData(data);
    } catch (error) {}
  };
  useEffect(() => {
    kbCategoryData();
  }, []);

  const kbDataList = kbCatData?.getCategoryDetails;

  useEffect(() => {
    setArticleTypeVal(kbDataList?.categoryType);
    const defaultValue = [
      {
        label: kbDataList?.departmentName,
        value: kbDataList?.departmentId,
      },
    ];
    setApiDepValue(defaultValue);
  }, [kbDataList]);
  let userName = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      userName = userObj?.user_name;
    }
  }
  const updateCategoryData = async (events, values) => {
    try {
      if (value !== '') {
        setCatLoad(true);
        const { data, errors } = await ApolloClientKnowledgeBaseService.mutate({
          mutation: UPDATE_CATEGORY,
          variables: {
            input: {
              _id: id,
              categoryData: {
                name: { en: values?.name_e, ar: values?.name_a },
                slug: values?.slug,
                shortDescription: {
                  en: values?.description_e,
                  ar: values?.description_a,
                },
                metaTitle: { en: values?.metaT_e, ar: values?.metaT_a },
                metaDescription: { en: values?.metaD_e, ar: values?.metaD_a },
                metaKeywords: { en: values?.metak_w, ar: '' },
                status: value,
                createdBy: userName,
                updatedBy: userName,
                icon: values?.icon,
                categoryType: articleType === '' ? articleTypeVal : articleType,
                departmentId:
                  selectedGroup?.value === null
                    ? apiDepValue?.[0]?.value
                    : selectedGroup?.value,
                departmentName:
                  selectedGroup?.label === null
                    ? apiDepValue?.[0]?.label
                    : selectedGroup?.label,
                position: Number(values?.position),
              },
            },
          },
          fetchPolicy: 'network-only',
        });
        setCreateCatData(data);
        setKbCatError(errors);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (kbCreateCatData?.updateCategoryDetails?._id !== undefined) {
      setCatLoad(false);
      toast.success('Category Successfully Created', {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });

      setCreateCatData('');
    } else {
      if (kbCreateCatData?.updateCategoryDetails?._id === '') {
        setCatLoad(false);
        toast.error('Category Not Created', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [kbCreateCatData]);
  const getArtList = async () => {
    try {
      const { data } = await ApolloClientKnowledgeBaseService.mutate({
        mutation: GET_ARTICLES_NAME_LIST,
        variables: {
          input: {
            categoryId: id,
          },
        },
        fetchPolicy: 'network-only',
      });
      setGetArtNameList(data);
    } catch (error) {}
  };
  useEffect(() => {
    getArtList();
  }, []);

  const artNameListFromApi =
    getArtNameList?.getCategoryArticlesNameList?.articlesNameData;
  useEffect(() => {
    const arrArtData = artNameListFromApi?.map((item, itemInx) => ({
      label: item?.title?.en,
      value: item?._id,
      position: parseInt(item?.position),
    }));
    setArticalsTreeData(arrArtData);
    const defaultArtData = artNameListFromApi?.map(
      (item, itemInx) => item?._id
    );
    setDefaultArticalsTreeData(defaultArtData);
  }, [artNameListFromApi]);

  const handleDrop = async (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem?.destination) return;
    var updatedList = itemList;
    // Remove dragged item
    const [reorderedItem] = updatedList?.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem?.destination?.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
    let posData = [];

    itemList?.map((item, iteInx) => {
      let obj = {
        _id: item?.value,
        position: iteInx,
      };
      posData?.push(obj);
    });

    try {
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_ARTICLES_DATA_POSITION,
        variables: {
          input: { positionData: posData },
        },
        fetchPolicy: 'no-cache',
      });
      setKbArtPositionData(data);
    } catch (error) {}
  };
  const kabArtPositionMessage = kabArtPosition?.updateArticlesPositions?.result;

  useEffect(() => {
    if (kabArtPositionMessage === 'Success') {
      setCatLoad(false);
      toast.success('Article Successfully Updated', {
        style: {
          border: '1px solid #fff',
          padding: '20px',
          color: '#ffffff',
          background: '#4CAF50',
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#4CAF50',
        },
      });

      setCreateCatData('');
    } else {
      if (kabArtPositionMessage === 'Failed') {
        setCatLoad(false);
        toast.error('Article Not Updated', {
          style: {
            border: '1px solid #fff',
            padding: '20px',
            color: '#ffffff',
            background: '#f44336',
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#f44336',
          },
        });
      }
    }
  }, [kabArtPosition]);
  let stsBtn = '';
  if (value === 0) {
    stsBtn = 'bg-primary';
  } else if (value === 1) {
    stsBtn = 'bg-success';
  } else if (value === 2) {
    stsBtn = 'bg-warning';
  } else {
    stsBtn = 'bg-soft-dark';
  }

  const getDepNameList = async (variables) => {
    try {
      const { data } = await ApolloClientKnowledgeBaseService.mutate({
        mutation: GET_DEPARTMENT_NAME_LIST,
        variables: { input: {} },
        fetchPolicy: 'network-only',
      });
      setDepNameList(data);
    } catch (error) {}
  };
  useEffect(() => {
    getDepNameList();
  }, []);
  const depNameListFromApi = getDepName?.getDepartmentsList?.departmentsData;
  useEffect(() => {
    const optionGroup = depNameListFromApi?.map((item, itemInx) => ({
      label: item?.name,
      value: item?._id,
      slug: item?.slug,
    }));
    setDepValue(optionGroup);
  }, [depNameListFromApi]);
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Categories</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <DashBreadcrumb
                title="Categories"
                breadcrumbItem="Add New Category"
              />

              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Col lg={8} md={8}>
                  <Card>
                    <CardBody>
                      <AvForm
                        ref={buttonRef}
                        onValidSubmit={(e, v) => {
                          updateCategoryData(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <FormGroup className="mb-3">
                            <AvField
                              type="select"
                              name="status"
                              label="Associate to Website"
                              className="form-select"
                              onChange={handleArticleType}
                              value={kbDataList?.categoryType}
                            >
                              <option value="internal">internal</option>
                              <option value="external">external</option>
                            </AvField>
                          </FormGroup>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="name_e"
                            label="Name - English "
                            placeholder="Type Something"
                            type="text"
                            value={kbDataList?.name?.en}
                            required
                            onChange={(e) => {
                              setCategoryName(e?.target?.value);
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="name_a"
                            label="Name - Arabic"
                            placeholder="Type Something"
                            type="text"
                            value={kbDataList?.name?.ar}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="slug"
                            label="Slug*"
                            type="text"
                            value={
                              categoryName === ''
                                ? kbDataList?.slug
                                : categoryName
                                    ?.replace(/[^A-Z0-9]+/gi, '-')
                                    ?.toLowerCase()
                            }
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="description_e"
                            label="Short Description - English"
                            type="textarea"
                            value={kbDataList?.shortDescription?.en}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="description_a"
                            label="Short Description - Arabic"
                            type="textarea"
                            value={kbDataList?.shortDescription?.ar}
                            required
                          />
                        </div>
                        <h5>Search Engine Optimization</h5>

                        <div className="mb-3">
                          <AvField
                            name="metaT_e"
                            label="Meta Title - English"
                            type="text"
                            value={kbDataList?.metaTitle?.en}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaT_a"
                            label="Meta Title - Arabic"
                            type="text"
                            value={kbDataList?.metaTitle?.ar}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaD_e"
                            label="Meta Description - English"
                            type="textarea"
                            value={kbDataList?.metaDescription?.en}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaD_a"
                            label="Meta Description - Arabic"
                            type="textarea"
                            value={kbDataList?.metaDescription?.ar}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metak_w"
                            label="Meta Keywords - English"
                            type="text"
                            value={kbDataList?.metaKeywords?.en}
                          />
                        </div>
                        <div className="mb-3">
                          <label>Departments*</label>
                          <Select
                            value={
                              selectedGroup === null
                                ? apiDepValue
                                : selectedGroup
                            }
                            onChange={handleSelectGroup}
                            options={depValue}
                            classNamePrefix="select2-selection"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="icon"
                            label="Category Icon"
                            type="text"
                            value={kbDataList?.icon}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="position"
                            label="Position*"
                            type="text"
                            value={kbDataList?.position}
                            required
                          />
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                  <label>Status</label>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col>
                      <label>Current</label>
                    </Col>

                    <Col className="textAlignRight">
                      {' '}
                      <Badge className={` ${stsBtn} me-2`}>
                        {toggleValue}{' '}
                      </Badge>
                    </Col>
                  </Row>

                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                  >
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                      onClick={() => {
                        buttonRef?.current?.submit();
                      }}
                    >
                      {saveCatLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{' '}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdownmenu-secondary dropdown-menu-end"
                      id="toggleData"
                      onClick={() => {
                        buttonRef?.current?.submit();
                      }}
                    >
                      <DropdownItem onClick={() => handleSelect(0)}>
                        Unpublished
                      </DropdownItem>

                      <DropdownItem onClick={() => handleSelect(1)}>
                        Published
                      </DropdownItem>

                      <DropdownItem onClick={() => handleSelect(2)}>
                        Draft
                      </DropdownItem>

                      <DropdownItem
                        onClick={() => {
                          tog_center();
                        }}
                      >
                        Set Schedule
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>

                  <Modal
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center();
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Set Schedule</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <label>Schedule (required)</label>
                        <Col>
                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="exchange"
                                  name="payment_mode"
                                  defaultChecked
                                  id="exchangeRadios"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exchangeRadios"
                                >
                                  Exchange
                                </label>
                              </div>
                            </Col>
                            <Col>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  value="Refund"
                                  name="payment_mode"
                                  id="refundRadios"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="refundRadios"
                                >
                                  Refund
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <label
                              htmlFor="example-datetime-local-input"
                              className="form-Label"
                            >
                              Publish on(required)
                            </label>
                            <input
                              className="form-control"
                              type="datetime-local"
                              defaultValue="2019-08-19T13:45:00"
                              id="example-datetime-local-input"
                            />
                          </Row>
                          <Row className="mt-3">
                            <Col>
                              <button
                                type="submit"
                                className="btn btn-danger mr-2"
                                data-toggle="modal"
                              >
                                Cancel
                              </button>

                              <button
                                type="submit"
                                className="btn btn-success"
                                data-toggle="modal"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Modal>

                  <Row className="mt-3">
                    <label>ASSIGNED ARTICLES</label>

                    <Col className="colWidth">
                      <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {itemList?.map((item, index) => (
                                <Draggable
                                  key={item?.value}
                                  draggableId={item?.value}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      className="item-container"
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <div>
                                        <img
                                          src={drapIcon}
                                          className="dragImage"
                                        />

                                        <span>
                                          {item?.label}
                                          {'     '}
                                        </span>
                                      </div>
                                      <div className="dragImage2">
                                        {' '}
                                        <Link
                                          to={`/EditNewArticle/${item?.value}`}
                                        >
                                          <img src={dropIcon} />
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};

export default KbUpdateCategoryForm;
